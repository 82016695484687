import {combineReducers} from 'redux';




import {netRequest_reducer} from '../app/utils/netReqRD';
import {board_reducer} from '../app/board/boardRD'


export const RootReducer = combineReducers(
    {
        // 这里添加各action 的处理 handle
        // 网络请求公共处理
        net_request: netRequest_reducer, 

        // 白板信息
        board_info: board_reducer, 
    }
);


