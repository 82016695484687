import React, { Component } from 'react'
import { connect } from 'react-redux'
import {appHost, mntnHost} from '../../comm/net'
import {netRequestThunk, actNetRequestClear} from '../utils/netReqRD'
import {actSetToken, getUserInfoSuccessHd, genTokenInfoSuccessHd} from './boardRD'

class BoardLoadCT extends Component {
    constructor(props){
        super(props);

        let {match, dispatch} = props;
        let userInfo = undefined
        let token = undefined
        if (match.params.userInfo !== undefined) {
            userInfo = match.params.userInfo
            userInfo = JSON.parse(userInfo)
            token = userInfo.Token
        }

        this.state = {
            token: token
        }

        // 设置 token
        dispatch(actSetToken(token))
    }

    componentWillUnmount(){
        let { dispatch } = this.props;
        dispatch(actNetRequestClear("getUserInfo"))
        dispatch(actNetRequestClear("genUserToken"))
    }

    componentDidMount() {
        let {token} = this.state
        let {dispatch} = this.props
        
        // 这里需要根据token信息获取用户信息
        if (token) {
            let absoluteUrl = appHost + '/cs/v1/app/user/get/userInfo'
            console.log("absoluteUrl = ", absoluteUrl)
            dispatch(netRequestThunk(undefined, undefined, 'getUserInfo', getUserInfoSuccessHd, undefined, absoluteUrl, token));

            // 这里需要刷新下token信息，以便后面的接口使用
            let absoluteUrl2 = mntnHost + '/cs/v1/mntn/user/gen/token'
            const msgBody = {
                Token: token
            }
            console.log("absoluteUrl = ", absoluteUrl2)
            dispatch(netRequestThunk(undefined, msgBody, 'genUserToken', genTokenInfoSuccessHd, undefined, absoluteUrl2, token));
        }        
    }

    render() {
        return null;
    }
}


const mapState = (state) => ({
    // defaultUser: getLoginDefaultUserName(state), 
    // loginStatus: getNetRequestStatus(state, 'loginRequest'), 
    // userInfo: getLoginUserInfo(state), 
    
});

export default connect(
    mapState, 
    null
)(BoardLoadCT);

