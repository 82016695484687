import {saveStoreState} from '../../comm/store'

const ACT_SET_USER_INFO = 'ACT_SET_USER_INFO'
const ACT_SET_USER_TOKEN = 'ACT_SET_USER_TOKEN'
const ACT_SET_USER_TOKEN_INFO = 'ACT_SET_USER_TOKEN_INFO'
const ACT_SET_BOARD_OWNER = 'ACT_SET_BOARD_OWNER'
const ACT_SET_BOARD_COURSEWARE = 'ACT_SET_BOARD_COURSEWARE'


// tokenInfo = {token: undefined, refreshToken: undefined, expireTime: undefined}
// userInfo = {nickName: undefined, sipNum: underfined}

const boardState = {
    token: undefined,
    userInfo: undefined, 
    tokenInfo: undefined, 
    boardOwner: undefined, 
    courseware: undefined, 
};


// 
export const board_reducer = (state=boardState, action) => {
    switch(action.type){
        case ACT_SET_USER_INFO:
        {
            let retState = Object.assign({}, state);
            retState.userInfo = action.userInfo;
            saveStoreState(retState)
            return retState
        }

        case ACT_SET_USER_TOKEN:
        {
            let retState = Object.assign({}, state);
            retState.token = action.token;
            return retState;
        }

        case ACT_SET_USER_TOKEN_INFO:
        {
            let retState = Object.assign({}, state);
            retState.tokenInfo = action.tokenInfo;    
            saveStoreState(retState)
            return retState
        }

        case ACT_SET_BOARD_OWNER:
            {
                let retState = Object.assign({}, state);
                retState.boardOwner = action.boardOwner;
                return retState;
            }

        case ACT_SET_BOARD_COURSEWARE:
            {
                let retState = Object.assign({}, state);
                retState.courseware = action.courseware;
                return retState;
            }

        default:
            return state;
    }
}

// select
export const getUserInfo = (state) => {
    if (state.board_info.userInfo !== undefined){
        return state.board_info.userInfo;
    }
    return undefined;
}

export const getToken = (state) => {
    if (state.board_info.token !== undefined){
        return state.board_info.token;
    }
    return undefined;
}

export const getTokenInfo = (state) => {
    if (state.board_info.tokenInfo !== undefined){
        return state.board_info.tokenInfo;
    }
    return undefined;
}

export const getBoardOwner = (state) => {
    if (state.board_info.boardOwner !== undefined){
        return state.board_info.boardOwner;
    }
    return undefined;
}

// action
export const actSetUserInfo = (userInfo) => {
    return {
        type: ACT_SET_USER_INFO, 
        userInfo: userInfo, 
    }
}

export const actSetToken = (token) => {
    return {
        type: ACT_SET_USER_TOKEN, 
        token: token, 
    }
}

export const actSetTokenInfo = (tokenInfo) => {
    return {
        type: ACT_SET_USER_TOKEN_INFO, 
        tokenInfo: tokenInfo, 
    }
}

export const actSetBoardOwner = (boardOwner) => {
    return {
        type: ACT_SET_BOARD_OWNER, 
        boardOwner: boardOwner, 
    }
}

// thunk 
export const genTokenInfoSuccessHd = (dispatch, rspBody, msgBody) => {
    const tokenInfo = {
        token: rspBody.Token, 
        refreshToken: rspBody.RefreshToken, 
        expireTime: rspBody.Expire
    };

    dispatch(actSetTokenInfo(tokenInfo));
}

export const getUserInfoSuccessHd = (dispatch, rspBody, msgBody) => {
    const userInfo = {
        sipNum: rspBody.SipInfo.SipNum, 
        nickName: rspBody.NickName
    };

    dispatch(actSetUserInfo(userInfo));
}

export const getBoardInfoSuccessHd = (dispatch, rspBody, msgBody) => {
    let boardOwner = undefined

    if (rspBody.BoardOwner === undefined || rspBody.BoardOwner === "" || rspBody.BoardOwner === null) {
        boardOwner = undefined
    }else{
        boardOwner = rspBody.BoardOwner
    }
    dispatch(actSetBoardOwner(boardOwner));
}

// courseware ����
// ACT_SET_BOARD_COURSEWARE
export const getCourseware = (state) => {
    if (state.board_info.courseware !== undefined){
        return state.board_info.courseware;
    }
    return undefined;
}

export const actSetBoardCourseware = (courseware) => {
    return {
        type: ACT_SET_BOARD_COURSEWARE, 
        courseware: courseware, 
    }
}

export const getCoursewareSuccessHd = (dispatch, rspBody, msgBody, successCb=undefined) => {
    let courseware = {
        coursewareId: msgBody.coursewareId, 
        pageNum: msgBody.pageNum, 
        pageList: rspBody.pageList, 
    }

    dispatch(actSetBoardCourseware(courseware));
    if (successCb !== undefined) {
        successCb(courseware)
    }
}

export const getCoursewareFailedHd = (dispatch, rspBody, msgBody) => {
    // ɶҲ�������ȴ�ӡ����־, ��չʾ����
    console.log("getCoursewareFailedHd: rspBody = , msgBody = ", rspBody, msgBody)
}



