import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import {fabric} from "fabric"
import {uuid4, Tool, Select, Pencil, Line, Arrow, Rectangle, Circle, Pan, 
    DefaultTool, History, Ellipse, Text} from '../../component/board/tools'
import {CANVAS_WH_FACTOR, editBarHeight} from '../../component/board/constUtils'


export class SketchPad extends PureComponent {
    static propTypes = {
        // the color of the line
        lineColor: PropTypes.string,
        // The width of the line
        lineWidth: PropTypes.number,
        // the fill color of the shape when applicable
        fillColor: PropTypes.string,
        // the background color of the sketch
        backgroundColor: PropTypes.string,
        // the opacity of the object
        opacity: PropTypes.number,
        // number of undo/redo steps to maintain
        undoSteps: PropTypes.number,
        // The tool to use, can be pencil, rectangle, circle, brush;
        tool: PropTypes.string,
        // image format when calling toDataURL
        imageFormat: PropTypes.string,
        // Sketch data for controlling sketch from
        // outside the component
        value: PropTypes.object,
        // value: PropTypes.string,
        // Set to true if you wish to force load the given value, even if it is  the same
        forceValue: PropTypes.bool,
        // Specify some width correction which will be applied on auto resize
        widthCorrection: PropTypes.number,
        // Specify some height correction which will be applied on auto resize
        heightCorrection: PropTypes.number,
        // Specify action on change
        onChange: PropTypes.func,
        // Default initial value
        defaultValue: PropTypes.object,
        // Sketch width
        width: PropTypes.number,
        // Sketch height
        height: PropTypes.number,
        // event object added
        onObjectAdded: PropTypes.func,
        // event object modified
        onObjectModified: PropTypes.func,
        // event object removed
        onObjectRemoved: PropTypes.func,
        // event mouse down
        onMouseDown: PropTypes.func,
        // event mouse move
        onMouseMove: PropTypes.func,
        // event mouse up
        onMouseUp: PropTypes.func,
        // event mouse out
        onMouseOut: PropTypes.func,
        // event object move
        onObjectMoving: PropTypes.func,
        // event object scale
        onObjectScaling: PropTypes.func,
        // event object rotating
        onObjectRotating: PropTypes.func,
        // Class name to pass to container div of canvas
        className: PropTypes.string,
        // Style options to pass to container div of canvas
        style: PropTypes.object,
      };

    static defaultProps = {
        lineColor: 'black',
        lineWidth: 10,
        fillColor: 'transparent',
        backgroundColor: 'transparent',
        opacity: 1.0,
        undoSteps: 25,
        tool: null,
        widthCorrection: 0,
        heightCorrection: 0,
        forceValue: false,
        onObjectAdded:()=>null,
        onObjectModified:()=>null,
        onObjectRemoved:()=>null,
        onMouseDown:()=>null,
        onMouseMove:()=>null,
        onMouseUp:()=>null,
        onMouseOut:()=>null,
        onObjectMoving:()=>null,
        onObjectScaling:()=>null,
        onObjectRotating:()=>null,
    };

    state = {
        parentWidth: 550,
        action: true
      };

    _initTools = (fabricCanvas) => {
        this._tools = {};
        this._tools[Tool.Select] = new Select(fabricCanvas, this);
        this._tools[Tool.Pencil] = new Pencil(fabricCanvas, this);
        this._tools[Tool.Line] = new Line(fabricCanvas, this);
        this._tools[Tool.Arrow] = new Arrow(fabricCanvas, this);
        this._tools[Tool.Rectangle] = new Rectangle(fabricCanvas, this);
        // this._tools[Tool.RectangleLabel] = new RectangleLabel(fabricCanvas);
        this._tools[Tool.Circle] = new Circle(fabricCanvas, this);
        this._tools[Tool.Ellipse] = new Ellipse(fabricCanvas, this);
        this._tools[Tool.Pan] = new Pan(fabricCanvas, this);
        this._tools[Tool.Text] = new Text(fabricCanvas, this);
        this._tools[Tool.DefaultTool] = new DefaultTool(fabricCanvas, this);
    };

    _publishChange() {
      let {dataChangHd, width, height} = this.props
    
      // 发布画布内容变化
      if (this._isUpdate || this._isDrawShape) {
        return
      }

      if (dataChangHd) {
        // 需要滞后一点时间后，再发布变化
        setTimeout(() => {
          const canvas = this._fc
          const width = canvas.getWidth()
          const height = canvas.getHeight()

          const data = canvas.toJSON()
          dataChangHd(JSON.stringify(data), width, height)
        }, 10)
        
      }
    }

    enableTouchScroll = () => {
        let canvas = this._fc;
        if (canvas.allowTouchScrolling) return;
        canvas.allowTouchScrolling = true
    };

    disableTouchScroll = () => {
        let canvas = this._fc;
        if (canvas.allowTouchScrolling) {
          canvas.allowTouchScrolling = false
        }
    };

    addImg = (dataUrl, options = {}) => {
        let canvas = this._fc;
        fabric.Image.fromURL(dataUrl, (oImg) => {
          let opts = {
            left: Math.random() * (canvas.getWidth() - oImg.width * 0.5),
            top: Math.random() * (canvas.getHeight() - oImg.height * 0.5),
            scale: 0.5
          };
          Object.assign(opts, options);
          oImg.scale(opts.scale);
          oImg.set({
            'left': opts.left,
            'top': opts.top
          });
          canvas.add(oImg);
        });
    };

    _onObjectAdded = (e) => {
        const {dataChangHd} = this.props;
        if (!this.state.action) {
          this.setState({ action: true });
          return
        }
        let obj = e.target;
        obj.__version = 1;
        // record current object state as json and save as originalState
        let objState = obj.toJSON();
        obj.__originalState = objState;
        let state = JSON.stringify(objState);
        // object, previous state, current state
        this._history.keep([obj, state, state])

        this._publishChange()

    };

    _onObjectMoving = (e) => {
        const {onObjectMoving} = this.props;
        onObjectMoving(e);
    };

    _onObjectScaling = (e) => {
        const {onObjectScaling} = this.props;
        onObjectScaling(e);
    };

    _onObjectRotating = (e) => {
        const {onObjectRotating} = this.props;
        onObjectRotating(e);
    };

    _onObjectModified = (e) => {
        const {dataChangHd} = this.props;
        let obj = e.target;
        obj.__version += 1;
        let prevState = JSON.stringify(obj.__originalState);
        let objState = obj.toJSON();
        // record current object state as json and update to originalState
        obj.__originalState = objState;
        let currState = JSON.stringify(objState);
        this._history.keep([obj, prevState, currState]);

        
        // 发布画布内容变化
        this._publishChange()
        // if (this._isUpdate) {
        //   return
        // }

        // if (dataChangHd) {
        //   const data = this._fc.toJSON()
        //   dataChangHd(JSON.stringify(data))
        // }

        // onObjectModified(e);
    };

    _onObjectRemoved = (e) => {
        const {dataChangHd, onObjectRemoved} = this.props;
        let obj = e.target;
        if (obj.__removed) {
          obj.__version += 1;
          return
        }
        obj.__version = 0;

        // 发布画布内容变化
        // if (this._isUpdate) {
        //   return
        // }

        // if (dataChangHd) {
        //   const data = this._fc.toJSON()
        //   dataChangHd(JSON.stringify(data))
        // }

        onObjectRemoved(e);
    };

    _onMouseDown = (e) => {
        const{onMouseDown} = this.props;
        this._selectedTool.doMouseDown(e);
        onMouseDown(e);
    };

    _onMouseMove = (e) => {
        const {onMouseMove} = this.props;
        this._selectedTool.doMouseMove(e);
        onMouseMove(e);
    };

    _onMouseOut = (e) => {
        const {onMouseOut} = this.props;
        this._selectedTool.doMouseOut(e);
        if (this.props.onChange) {
          let onChange = this.props.onChange;
          setTimeout(() => {
            onChange(e.e)
          }, 10)
        }
        onMouseOut(e);
    };

    _onMouseUp = (e) => {
        const {onMouseUp} = this.props;
        this._selectedTool.doMouseUp(e);
        // Update the final state to new-generated object
        // Ignore Path object since it would be created after mouseUp
        // Assumed the last object in canvas.getObjects() in the newest object
        if (this.props.tool !== Tool.Pencil) {
          const canvas = this._fc;
          const objects = canvas.getObjects();
          const newObj = objects[objects.length - 1];
          if (newObj && newObj.__version === 1) {
            newObj.__originalState = newObj.toJSON();
          }
        }
        
        if (this.props.onChange) {
          let onChange = this.props.onChange;
          setTimeout(() => {
            onChange(e.e)
          }, 10)
        }
        onMouseUp(e);
    };

    _calcBoardWidthAndHeight(clientWidth, clientHeight) {
      let srcWidth = clientWidth
      let srcHeight = clientHeight - editBarHeight
      const factor = CANVAS_WH_FACTOR
      let result = {
          width: srcWidth, 
          height: srcHeight
      }

      let wFactor = srcWidth / srcHeight
      if (wFactor > factor) {
          // 以高度为准计算
          result.height = srcHeight
          result.width = srcHeight * factor
      }else {
          // 以宽度为准计算
          result.width = srcWidth
          result.height = srcWidth / factor
      }
      return result
  }

    _resize = (e, canvasWidth = null, canvasHeight = null) => {
        if (e) e.preventDefault();
        let { widthCorrection, heightCorrection } = this.props;
        let canvas = this._fc;
        let { offsetWidth, clientHeight } = this._container;
        let prevWidth = canvasWidth || canvas.getWidth();
        let prevHeight = canvasHeight || canvas.getHeight();
        let wfactor = ((offsetWidth - widthCorrection) / prevWidth).toFixed(2);
        let hfactor = ((clientHeight - heightCorrection) / prevHeight).toFixed(2);
        canvas.setWidth(offsetWidth - widthCorrection);
        canvas.setHeight(clientHeight - heightCorrection);
        if (canvas.backgroundImage) {
          // Need to scale background images as well
          let bi = canvas.backgroundImage;
          bi.width = bi.width * wfactor;
          bi.height = bi.height * hfactor
        }
        let objects = canvas.getObjects();
        for (let i in objects) {
          let obj = objects[i];
          let scaleX = obj.scaleX;
          let scaleY = obj.scaleY;
          let left = obj.left;
          let top = obj.top;
          let tempScaleX = scaleX * wfactor;
          let tempScaleY = scaleY * hfactor;
          let tempLeft = left * wfactor;
          let tempTop = top * hfactor;
          obj.scaleX = tempScaleX;
          obj.scaleY = tempScaleY;
          obj.left = tempLeft;
          obj.top = tempTop;
          obj.setCoords()
        }
        this.setState({
          parentWidth: offsetWidth
        });
        canvas.renderAll();
        canvas.calcOffset();
    };

    _resize2 = (e, canvasWidth = null, canvasHeight = null) => {
      if (e) e.preventDefault();

      

      // let {width, height} = this.props

      let canvas = this._fc;

      console.log("enter _resize2 canvas = ", canvas)


      let {clientWidth, clientHeight} = document.documentElement
      const sizeInfo = this._calcBoardWidthAndHeight(clientWidth, clientHeight)

      let prevWidth = canvas.getWidth();
      let prevHeight = canvas.getHeight();

      let wfactor = sizeInfo.width / prevWidth
      let hfactor = sizeInfo.height/ prevHeight

      canvas.setWidth(sizeInfo.width);
      canvas.setHeight(sizeInfo.height);

      if (canvas.backgroundImage) {
        // Need to scale background images as well
        let bi = canvas.backgroundImage;
        bi.scaleX = bi.scaleX * wfactor;
        bi.scaleY = bi.scaleY * hfactor;
        bi.left = (sizeInfo.width / 2)
      }
      let objects = canvas.getObjects();
      for (let i in objects) {
        let obj = objects[i];
        let scaleX = obj.scaleX;
        let scaleY = obj.scaleY;
        let left = obj.left;
        let top = obj.top;
        let tempScaleX = scaleX * wfactor;
        let tempScaleY = scaleY * hfactor;
        let tempLeft = left * wfactor;
        let tempTop = top * hfactor;
        obj.scaleX = tempScaleX;
        obj.scaleY = tempScaleY;
        obj.left = tempLeft;
        obj.top = tempTop;
        obj.setCoords()
      }
      // this.setState({
      //   parentWidth: sizeInfo.width
      // });
      canvas.renderAll();
      canvas.calcOffset();
  };

    _backgroundColor = (color) => {
        if (!color) return;
        let canvas = this._fc;
        canvas.setBackgroundColor(color, () => canvas.renderAll())
    };

    zoom = (factor) => {
        let canvas = this._fc;
        let objects = canvas.getObjects();
        for (let i in objects) {
          objects[i].scaleX = objects[i].scaleX * factor;
          objects[i].scaleY = objects[i].scaleY * factor;
          objects[i].left = objects[i].left * factor;
          objects[i].top = objects[i].top * factor;
          objects[i].setCoords();
        }
        canvas.renderAll();
        canvas.calcOffset();
    };

    undo = () => {
        let history = this._history;
        let currentInfo = history.getCurrent()
        if (currentInfo === null){
          return
        }
        let [obj, prevState, currState] = currentInfo;
        history.undo();
        if (obj.__removed) {
          this.setState({ action: false }, () => {
            this._fc.add(obj);
            obj.__version -= 1;
            obj.__removed = false;
          });
        } else if (obj.__version <= 1) {
          this._fc.remove(obj);
        } else {
          obj.__version -= 1;
          obj.setOptions(JSON.parse(prevState));
          obj.setCoords();
          this._fc.renderAll()
        }

        // 发布画布内容变化
        this._publishChange()
        
    };

    redo = () => {
        let history = this._history;
        if (history.canRedo()) {
          let canvas = this._fc;
          //noinspection Eslint
          let [obj, prevState, currState] = history.redo();
          if (obj.__version === 0) {
            this.setState({ action: false }, () => {
              canvas.add(obj);
              obj.__version = 1
            })
          } else {
            obj.__version += 1;
            obj.setOptions(JSON.parse(currState))
          }
          obj.setCoords();
          canvas.renderAll();

          // 发布画布内容变化
          this._publishChange()
          // if (this.props.onChange) {
          //   this.props.onChange()
          // }
        }
    };

    canUndo = () => {
        return this._history.canUndo()
    };

    canRedo = () => {
        return this._history.canRedo()
    };

    toDataURL = (options) => this._fc.toDataURL(options);

    toJSON = (propertiesToInclude) => {
      // const objs = this._fc.toObject()
      // console.log("objs = ", objs)
      // return objs
      return this._fc.toJSON(propertiesToInclude);
    }

    fromJSON = (json) => {
        if (!json) return;
        let canvas = this._fc;
        // 手动清除，然后手动添加objs
        // 清除objs
        this._isUpdate = true

        canvas.forEachObject((o) => canvas.remove(o));
        let serialized = JSON.parse(json)
        fabric.util.enlivenObjects(serialized.objects, (objs) => {
          objs.forEach((item) => {
              canvas.add(item);
          });
          canvas.renderAll(); // Make sure to call once we're ready!
      });

      setTimeout(()=>{
          this._isUpdate = false
        }, 100)

    };

    loadPad = (width, height, json) => {
      if (!json) return;
      if (width === undefined || width === 0) return;
      if (height === undefined || height === 0) return;

      let canvas = this._fc;
      const canvasWidth = canvas.getWidth()
      const canvasHeight = canvas.getHeight()
      let scaleX = canvasWidth / width
      let scaleY = canvasHeight / height
      // console.log("scaleX = , scaleY = ", scaleX, scaleY)
      let serialized = JSON.parse(json)
      for (let item of serialized.objects) {
        item.scaleX = item.scaleX * scaleX
        item.scaleY = item.scaleY * scaleY
        item.left = item.left * scaleX
        item.top = item.top * scaleY
      }
      // console.log("serialized.objects = ", serialized.objects)

      //start loading
      this._isUpdate = true
      canvas.forEachObject((o) => canvas.remove(o));
        fabric.util.enlivenObjects(serialized.objects, (objs) => {
          objs.forEach((item) => {
              canvas.add(item);
          });
          canvas.renderAll(); // Make sure to call once we're ready!
      });

      setTimeout(()=>{
        this._isUpdate = false
      }, 100)
    }

    fromJSON2 = (json) => {
      if (!json) return;
      let canvas = this._fc;
      // 这里需要置标识位，防止更新的数据触发循环更新
      setTimeout(() => {
        this._isUpdate = true
        canvas.loadFromJSON(json, () => {
          if(this.props.tool === Tool.DefaultTool){
            canvas.isDrawingMode = canvas.selection = false;
            canvas.forEachObject((o) => o.selectable = o.evented = false);
          }
          
          canvas.renderAll();
          // canvas.renderTop()
          // if (this.props.onChange) {
          //   this.props.onChange()
          // }
        });
        setTimeout(
          () => {
            this._isUpdate = false
            
          }, 100)
        
      }, 100)
  };

    clear = (propertiesToInclude) => {
        // let discarded = this.toJSON(propertiesToInclude);
        // this._fc.clear();
        const canvas = this._fc
        canvas.forEachObject((o) => canvas.remove(o));
        this._history.clear();
        // 发布画布内容变化
        this._publishChange()
        // return discarded
    };

    removeSelected = () => {
        let canvas = this._fc;
        let activeObj = canvas.getActiveObject();
        if (activeObj) {
          let selected = [];
          if (activeObj.type === 'activeSelection') {
            activeObj.forEachObject(obj => selected.push(obj));
          } else {
            selected.push(activeObj)
          }
          selected.forEach(obj => {
            obj.__removed = true;
            let objState = obj.toJSON();
            obj.__originalState = objState;
            let state = JSON.stringify(objState);
            this._history.keep([obj, state, state]);
            canvas.remove(obj);
          });
          canvas.discardActiveObject();
          canvas.requestRenderAll();

          // 发布画布内容变化
          this._publishChange()

        }
    };

    copy = () => {
        let canvas = this._fc;
        const activeObj = canvas.getActiveObject()
        if (activeObj) {
            activeObj.clone(cloned => this._clipboard = cloned)
        }
        // canvas.getActiveObject().clone(cloned => this._clipboard = cloned);
    };

    paste = () => {
        // clone again, so you can do multiple copies.
        if (this._clipboard === undefined) {
            return
        }

        this._clipboard.clone(clonedObj => {
          let canvas = this._fc;
          canvas.discardActiveObject();
          clonedObj.set({
            left: clonedObj.left + 16,
            top: clonedObj.top + 16,
            evented: true,
          });
          if (clonedObj.type === 'activeSelection') {
            // active selection needs a reference to the canvas.
            clonedObj.canvas = canvas;
            clonedObj.forEachObject(obj => canvas.add(obj));
            clonedObj.setCoords();
          } else {
            canvas.add(clonedObj);
          }
          this._clipboard.top += 16;
          this._clipboard.left += 16;
          canvas.setActiveObject(clonedObj);
          canvas.requestRenderAll();
        });
    };

    setBackgroundFromDataUrl = (dataUrl, options = {}) => {
        let canvas = this._fc;
        if (options.stretched) {
          delete options.stretched;
          Object.assign(options, {
            width: canvas.width,
            height: canvas.height
          })
        }
        if (options.stretchedX) {
          delete options.stretchedX;
          Object.assign(options, {
            width: canvas.width
          })
        }
        if (options.stretchedY) {
          delete options.stretchedY;
          Object.assign(options, {
            height: canvas.height
          })
        }
        let img = new Image();
        img.setAttribute('crossOrigin', 'anonymous');
        img.onload = () => canvas.setBackgroundImage(new fabric.Image(img),
          () => canvas.renderAll(), options);
        img.src = dataUrl
    };

    addText = (text, options = {}) => {
        let {lineWidth, lineColor} = this.props
        

        let canvas = this._fc;
        let {left, top} = options
        if (left === undefined){
            left = canvas.getWidth() * 0.5
        }
        if (top === undefined) {
            top = canvas.getHeight() * 0.5
        }

        let fontSize = 12
        if (lineWidth === 3) {
            fontSize = 18
        }else if (lineWidth === 5){
            fontSize = 24
        }

        const ops = {
            left: left, 
            top: top, 
            // fontColor: lineColor, 
            fontSize: fontSize
        }

        let iText = new fabric.IText(text, ops);
        iText.setColor(lineColor)
      
        canvas.add(iText);
    };

    callEvent = (e, eventFunction) => {
        if(this._selectedTool)
          eventFunction(e);
    }

    setBackgroundImageFromUrl = (url, canvansInfo=undefined) => {
      console.log("@@@@3: canvansInfo = ", canvansInfo)
      this._setBgImageFromUrl(url, canvansInfo=canvansInfo)
      // 需要清除画板内容
      // this.clear()
    }

    _setBgImageFromUrl = (url, canvansInfo=undefined) => {
      const canvas = this._fc
      fabric.Image.fromURL(url, function(img) {
        let factor = 1
        let srcWidth = canvas.width
        let srcHeight = canvas.height
        if (canvansInfo !== undefined){
          srcWidth = canvansInfo.width
          srcHeight = canvansInfo.height
          factor = canvas.width / srcWidth
        }

        const scaleX = srcWidth / img.width
        const scaleY = srcHeight / img.height

        let scaleFator = scaleX > scaleY ? scaleY : scaleX
        scaleFator = scaleFator * factor

        canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), 
          { left: canvas.width/2, 
            originX: 'center', 
            originY: 'top', 
            scaleY: scaleFator, 
            scaleX: scaleFator, 
            excludeFromExport: true, 
          }
        );
      });
    }

    componentDidMount = () => {
        let {
          tool,
          value,
          undoSteps,
          defaultValue,
          backgroundColor, 
          initData
        } = this.props;
    
        let canvas = this._fc = new fabric.Canvas(this._canvas/*, {
             preserveObjectStacking: false,
             renderOnAddRemove: false,
             skipTargetFind: true
             }*/);
    
        this._initTools(canvas);
    
        // set initial backgroundColor
        this._backgroundColor(backgroundColor)
    
        let selectedTool = this._tools[tool];
        if(selectedTool)
          selectedTool.configureCanvas(this.props);
        this._selectedTool = selectedTool;
    
        // Control resize
        window.addEventListener('resize', this._resize2, false);
    
        // Initialize History, with maximum number of undo steps
        this._history = new History(undoSteps, false);

        this._isUpdate = false
        this._isDrawShape = false
        this._wheelPoints = [];

        // window.addEventListener('keyup', this._keyUpHd, false);   
    
        // Events binding
        canvas.on('object:added', e => this.callEvent(e, this._onObjectAdded));
        canvas.on('object:modified', e => this.callEvent(e, this._onObjectModified));
        canvas.on('object:removed', e => this.callEvent(e, this._onObjectRemoved));
        canvas.on('mouse:down', e => this.callEvent(e, this._onMouseDown));
        canvas.on('mouse:move', e => this.callEvent(e, this._onMouseMove));
        canvas.on('mouse:up', e =>  this.callEvent(e, this._onMouseUp));
        canvas.on('mouse:out', e => this.callEvent(e, this._onMouseOut));
        canvas.on('object:moving', e => this.callEvent(e, this._onObjectMoving));
        canvas.on('object:scaling', e => this.callEvent(e, this._onObjectScaling));
        canvas.on('object:rotating', e => this.callEvent(e, this._onObjectRotating));

    
        this.disableTouchScroll();

        // this._wheelPoints = []
    
        // this._resize();
    
        // initialize canvas with controlled value if exists
        (value || defaultValue) && this.fromJSON(value || defaultValue);
        
    };

    wheelHd(e) {
      if (!this._fc) {
        return;
      }

      if (e.type !== "wheel"){
        return;
      }

      if (e.deltaY > 0) {
        // 放大
        let srcFactor = this._fc.getZoom()
          if (srcFactor < 3) {
              srcFactor = srcFactor + 0.2
              let point = this._fc.getPointer(e, true)
              this._fc.zoomToPoint(point, srcFactor)
              this._wheelPoints.push(point)
          }

      }else if (e.deltaY < 0) {
        // 缩小
        let srcFactor = this._fc.getZoom()
        if (srcFactor >= 1.2) {
            srcFactor = srcFactor - 0.2
            // let point = this._fc.getPointer(e, true)
            let point = this._wheelPoints.pop();
            this._fc.zoomToPoint(point, srcFactor)
        }
      }
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this._resize);
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.state.parentWidth !== prevState.parentWidth
          || this.props.width !== prevProps.width
          || this.props.height !== prevProps.height) {
    
          this._resize()
        }
    
        if (this.props.tool !== prevProps.tool) {
          this._selectedTool = this._tools[this.props.tool];
          //Bring the cursor back to default if it is changed by a tool
          this._fc.defaultCursor = 'default';
          
        }

        if(this._selectedTool){
            this._selectedTool.configureCanvas(this.props);
        }
    
        if (this.props.backgroundColor !== prevProps.backgroundColor) {
          this._backgroundColor(this.props.backgroundColor)
        }
    
        if ((this.props.value !== prevProps.value) || (this.props.value && this.props.forceValue)) {
          this.fromJSON(this.props.value);
        }
    };

    render() {
        let {width, height, padClass} = this.props
        if (padClass === undefined) {
          padClass = "sketchPad"
        }
        return (
            <div className={padClass} id="al_sketch_pad" ref={(c) => this._container = c}>
                <canvas className="sketchCanvas" id={uuid4()} ref={(c) => this._canvas = c} width={width} height={height}>
                    Sorry, Canvas HTML5 element is not supported by your browser:(
                </canvas>
                
                
            </div>
        )
    }
}


