import React from 'react'
import { Icon, Input, Button } from 'antd'
import {PlusCircleOutlined} from '@ant-design/icons'
import { PenIcon, SelectIcon, StrokeIcon, ShapeIcon, ColorIcon, TextIcon, RubberIcon, ImportIcon, UndoIcon, 
  RedoIcon, AmplificationIcon, SaveIcon, LockedIcon, UnlockIcon, SlideIcon, ArrowIcon, TriangleIcon, 
  ImageIcon, LineIcon, CurveIcon, DelIcon, CopyIcon, BoldIcon, EditIcon } from './iconSvg'

const {TextArea} = Input

const IconMap = {
  'select': <SelectIcon />,
  'stroke': <StrokeIcon />,
  'pen': <PenIcon />,
  'straight': <div style={{ width: '22px', border: 'solid 1px #926dea', marginTop: '12px' }} />,
  'shape': <ShapeIcon />,
  'color': <ColorIcon />,
  'text': <TextIcon />,
  'rubber': <RubberIcon />,
  'import': <ImportIcon />,
  'undo': <UndoIcon />,
  'redo': <RedoIcon />,
  'amp': <AmplificationIcon />,
  'save': <SaveIcon />,
  'locked': <LockedIcon />,
  'unlock': <UnlockIcon />,
  'slide': <SlideIcon />, 
  'arrow': <ArrowIcon />, 
  'triangle': <TriangleIcon />, 
  'image': <ImageIcon />, 
  'line': <LineIcon />, 
  'curve': <CurveIcon />, 
  'delete': <DelIcon />, 
  'copy': <CopyIcon />, 
  'bold': <BoldIcon />, 
  'edit': <EditIcon />, 
  'eye': <Icon type="eye-o" style={{ fontSize: '24px', color: '#926dea' }}/>
}

export class EditBtn extends React.Component {

  render() {
    const { type, text, arrow, onClick, selected, color, active } = this.props
    let tmpClass = "bd_editBtn"
    if (selected) {
      tmpClass = "bd_editBtn bd_opSelected"
    }
    return (
      <div className={tmpClass} onClick={e => active ? (onClick ? onClick() : undefined) : undefined }>
        <div className="bd_iconContainer">
          <span className="bd_icon">{IconMap[type]}</span>
          {(type === 'color') && color ? <div className="bd_colorCircle" style={{ backgroundColor: color }} ></div> : null}
          {arrow ? <div className="bd_triangle"></div> : null}
        </div>
        <div className="bd_iconText">
          {text}
        </div>
      </div>
    )
  }
}

export class BdTextArea extends React.Component {
  render() {
    let {display, setX, setY, textValue, textValueOnChangeHd, textAddHd, textCancelHd} = this.props
    let displayStr = 'none'
    if (display) {
      displayStr = 'block'
    }
    if (setY) {
      setY = setY + 60
    }
  
    let displayX = setX + "px"
    let displayY = setY + "px"

    return (
      <div style={{display: displayStr, position: 'absolute', top: displayY, left: displayX, width: '220px'} }>
        <div>
          <Input 
            value={textValue}
            size="large"
            onPressEnter={e => textAddHd ? textAddHd() : undefined}
            onChange={e=> textValueOnChangeHd ? textValueOnChangeHd(e) : undefined}
          />
          <div style={{marginTop: 3, marginLeft: 2}}>
              <Button size="small" shape="round" type="primary" onClick={e => textAddHd ? textAddHd() : undefined}>确定</Button>
              <Button size="small" shape="round" type="default" style={{marginLeft: 2}}
                onClick={e => textCancelHd ? textCancelHd() : undefined}
              >取消</Button>
          </div>
          
        </div>
        
     </div>
    )

  }
}

