import React, { Component } from 'react'
import { connect } from 'react-redux'

import {Row, Col} from 'antd'

import {page2Urls, pageUrls} from './data'
import {SlidePreview} from '../board/slidePreview'


class TestCT extends Component {
    constructor(props){
        super(props);

        this.state = {

        }

    }

    render() {
        return (
            <div>
                <Row justify="center">
                    <Col span={3}></Col>


                    <Col span={16}>

                
                    <SlidePreview 
                    currentPage={1}
                    pageList={page2Urls}
                    pageNum={20}
                    fileName="test"
                    />
                </Col>
                <Col span={3}></Col>
                </Row>
            </div>
        );
    }

}


const mapState = (state) => ({
   
    
});

export default connect(
    mapState, 
    null
)(TestCT);

