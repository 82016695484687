import React, { Component } from 'react';
import { Icon, Row, Col, Dropdown, Menu, Popconfirm, Upload} from 'antd';
import {UploadOutlined, LeftOutlined, RightOutlined, EllipsisOutlined} from '@ant-design/icons'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import { PenIcon, RubberIcon, ClearIcon, AuthIcon, StrokeIcon, LineIcon, CurveIcon, ArrowIcon, 
    TriangleIcon, DelIcon, CopyIcon} from '../../component/board/iconSvg'
import {EditBtn, BdTextArea} from '../../component/board/editBtn'
import {Tool} from '../../component/board/tools'
import {SketchPad} from './sketchPad'
import {colorsMenu, CANVAS_WH_FACTOR, editBarHeight, OP_TYPE, LINE_WIDTH} from '../../component/board/constUtils'
// import {InitData} from '../../component/board/dataJson'

import {supportFileTypes} from '../../comm/config'


export class WhiteBoard extends Component {
    constructor(props){
        super(props)

        this.renderStrokeMenu = this.renderStrokeMenu.bind(this)
        this.renderShapeMenu = this.renderShapeMenu.bind(this)
        this.renderEditMenu = this.renderEditMenu.bind(this)
        this.renderColorMenu = this.renderColorMenu.bind(this)
        this.renderSlideMenu = this.renderSlideMenu.bind(this)
        this.pencilOnClickHd = this.pencilOnClickHd.bind(this)
        this.selectOnClickHd = this.selectOnClickHd.bind(this)
        this.shapeOnClickHd = this.shapeOnClickHd.bind(this)
        this.getBtnSelectFlag = this.getBtnSelectFlag.bind(this)
        this.setBackgroundImageFromUrl = this.setBackgroundImageFromUrl.bind(this)

        this.textToolMouseDownHd = this.textToolMouseDownHd.bind(this)
        this.textToolOnHd = this.textToolOnHd.bind(this)
        this.textValueOnChangeHd = this.textValueOnChangeHd.bind(this)
        this.textAddHd = this.textAddHd.bind(this)
        this.textCancelHd = this.textCancelHd.bind(this)

        this.historyUndoHd = this.historyUndoHd.bind(this)
        this.historyReduHd = this.historyReduHd.bind(this)

        this.saveHd = this.saveHd.bind(this)
        this.loadObjsFromJson = this.loadObjsFromJson.bind(this)
        this.clearBoardContent = this.clearBoardContent.bind(this)
        this._calcBoardWidthAndHeight = this._calcBoardWidthAndHeight.bind(this)
        this.getBoardCanvasInfo = this.getBoardCanvasInfo.bind(this)

        this.mkEditTools = this.mkEditTools.bind(this)
        this.mkEditTools2 = this.mkEditTools2.bind(this)
        this.wheelHd = this.wheelHd.bind(this)

        let {isView} = props
        let currentTool = Tool.Pencil
        if (isView) {
            currentTool = Tool.DefaultTool
        }

        this.state = {
            color: "blue", 
            colorVisible: false, 
            lineWidth: 3, 
            currentTool: currentTool, 
            currentShape: "curve", 
            undoSteps: 20, 
            textDsiplay: false, 
            textX: undefined, 
            textY: undefined, 
            textDisplayX: undefined, 
            textDisplayY: undefined, 
            textContent: "", 
            canUndo: true, 
            canRedo: false, 
        }
        
    }

    // 对外提供函数 start
    getBoardCanvasInfo(){
        let canvans = this._sketch._fc
        let canvansInfo = {
            width: canvans.width,
            height: canvans.height
        }
        return canvansInfo
    }

    setBackgroundImageFromUrl(url, canvansInfo=undefined) {
        this._sketch.setBackgroundImageFromUrl(url, canvansInfo=canvansInfo)
    }

    clearBoardContent() {
        this._sketch.clear()
    }

    loadObjsFromJson(jsonStr, width, height) {
        // this._sketch.fromJSON(jsonStr)
        this._sketch.loadPad(width, height, jsonStr)
    }

    // 对外提供函数 end

    pencilOnClickHd(lineWidth) {
        let {currentTool, currentShape} = this.state

        switch(currentTool) {
            case Tool.Pencil:
            case Tool.Arrow:
            case Tool.Circle:
            case Tool.Line:
            case Tool.Rectangle:
            case Tool.Ellipse:
            case Tool.Text:
                break;
            
            default:
                currentTool = Tool.Pencil
                break;

        }

        let newState = Object.assign({}, this.state);
        newState.currentTool = currentTool;
        newState.lineWidth = lineWidth;
        newState.textDsiplay = false;
        newState.textContent = "";
        this.setState(newState);

    }

    selectOnClickHd() {
        let newState = Object.assign({}, this.state);
        newState.currentTool = Tool.Select;
        newState.textDsiplay = false;
        newState.textContent = "";
        this.setState(newState);
    }

    shapeOnClickHd(shape) {
        let {currentTool, currentShape} = this.state
        switch(shape) {
            case "curve":
                currentShape = "curve"
                currentTool = Tool.Pencil
                break;

            case "line":
                currentShape = "line"
                currentTool = Tool.Line
                break;
            
            case "arrow":
                currentShape = "arrow"
                currentTool = Tool.Arrow
                break;

            case "rect":
                currentShape = "rect"
                currentTool = Tool.Rectangle
                break;

            case "circle":
                currentShape = "circle"
                currentTool = Tool.Circle
                break;

            case "ellipse":
                currentShape = "ellipse"
                currentTool = Tool.Ellipse
                break;
        }

        let newState = Object.assign({}, this.state);
        newState.currentTool = currentTool;
        newState.currentShape = currentShape;
        newState.textDsiplay = false;
        newState.textContent = "";
        this.setState(newState);
    }

    getBtnSelectFlag(btnName) {
        let {currentTool} = this.state
        let ret = false
        switch(btnName) {
            case "select": 
                if (currentTool === Tool.Select) {
                    ret = true
                }
                break;

            case "shape":
                const toolList = [Tool.Pencil, Tool.Line, Tool.Arrow, Tool.Circle, Tool.Ellipse, Tool.Rectangle]
                if (toolList.includes(currentTool)){
                    ret = true
                }
                break;

            case "text":
                if (currentTool === Tool.Text) {
                    ret = true
                }
                break;
        }

        return ret
    }

    // text tool start
    textToolMouseDownHd(pointX, pointY, width) {
        let {clientWidth, clientHeight} = document.documentElement
        const textDisplayX = ((clientWidth - width)/2 + pointX).toFixed(0)
        const textDisplayY = pointY

        let newState = Object.assign({}, this.state);
        newState.textDsiplay = true;
        newState.textX = pointX;
        newState.textY = textDisplayY;
        newState.textDisplayX = textDisplayX;
        newState.textDisplayY = textDisplayY;
        this.setState(newState);
    }

    textToolOnHd() {
        let newState = Object.assign({}, this.state);
        newState.currentTool = Tool.Text;
        this.setState(newState);
    }

    textValueOnChangeHd({ target: { value } }) {
        let newState = Object.assign({}, this.state);
        newState.textContent = value;
        this.setState(newState);
    }

    textAddHd() {
        let {textContent, textX, textY} = this.state
        this._sketch.addText(textContent, {left: textX, top: textY})

        let newState = Object.assign({}, this.state);
        newState.textContent = "";
        newState.textDsiplay = false;
        newState.currentTool = Tool.Select;
        this.setState(newState);
    }

    textCancelHd() {
        let newState = Object.assign({}, this.state);
        newState.currentTool = Tool.Select;
        newState.textDsiplay = false;
        newState.textContent = "";
        this.setState(newState);
    }
    // text tool end

    // edit tool start
    editCopyHd() {
        this._sketch.copy()
        this._sketch.paste()
    }

    editDelHd() {
        this._sketch.removeSelected()
    }

    editClearHd() {
        this._sketch.clear()
    }
    // edit tool end

    // history tool start
    historyUndoHd() {
        this._sketch.undo();

        let newState = Object.assign({}, this.state);
        newState.canUndo = this._sketch.canUndo();
        newState.canRedo = this._sketch.canRedo();
        this.setState(newState);
    }

    historyReduHd() {
        this._sketch.redo()

        let newState = Object.assign({}, this.state);
        newState.canUndo = this._sketch.canUndo();
        newState.canRedo = this._sketch.canRedo();
        this.setState(newState);
    }
    // history tool end

    saveHd(){
        let jsonData = this._sketch.toJSON()
        console.log('jsonData = ', JSON.stringify(jsonData))
    }

    
    renderStrokeMenu() {
        let {lineWidth} = this.state
        let keyStr = "line_" + lineWidth
        return (
            <Menu className="bd_customMenu" selectedKeys={[keyStr]}>
                <Menu.Item key='line_1'>
                    <div className="menuItem" onClick={e => this.pencilOnClickHd(1)}>
                        <div className="line_1" />
                    </div>
                </Menu.Item>
                <Menu.Item key='line_3'>
                    <div className="menuItem" onClick={e => this.pencilOnClickHd(3)}>
                        <div className="line_2" />
                    </div>
                </Menu.Item>
                <Menu.Item key='line_5'>
                    <div className="menuItem" onClick={e => this.pencilOnClickHd(5)}>
                        <div className="line_3" />
                    </div>
                </Menu.Item>
            </Menu>
        )
    }

    renderShapeMenu() {
        let {currentShape} = this.state

        return (
            <Menu className="bd_customMenu" selectedKeys={[currentShape]}>
                <Menu.Item key='curve'>
                    <div className="menuItem" onClick={e => this.shapeOnClickHd("curve")}>
                        <span className="itemIcon"><CurveIcon /></span>
                        <span className="itemText">曲线</span>
                    </div>
                </Menu.Item>

                <Menu.Item key='line'>
                    <div className="menuItem" onClick={e => this.shapeOnClickHd("line")}>
                        <span className="itemIcon"><LineIcon /></span>
                        <span className="itemText">直线</span>
                    </div>
                </Menu.Item>
                <Menu.Item key='arrow'>
                    <div className="menuItem" onClick={e => this.shapeOnClickHd("arrow")}>
                        <span className="itemIcon"><ArrowIcon /></span>
                        <span className="itemText">箭头</span>
                    </div>
                </Menu.Item>
                <Menu.Item key='rect'>
                    <div className="menuItem" onClick={e => this.shapeOnClickHd("rect")}>
                        <span className="itemIcon"><div className="rect" /></span>
                        <span className="itemText">矩形</span>
                    </div>
                </Menu.Item>
                <Menu.Item key='circle'>
                    <div className="menuItem" onClick={e => this.shapeOnClickHd("circle")}>
                        <span className="itemIcon"><div className="circle" /></span>
                        <span className="itemText">圆形</span>
                    </div>
                </Menu.Item>

                <Menu.Item key='ellipse'>
                    <div className="menuItem" onClick={e => this.shapeOnClickHd("ellipse")}>
                    <span className="itemIcon"><div className="ellipse" /></span>
                        <span className="itemText">椭圆形</span>
                    </div>
                </Menu.Item>

                
                {/* <Menu.Item key='triangle'>
                    <div className="menuItem" onClick={undefined}>
                        <span className="itemIcon"><TriangleIcon /></span>
                        <span className="itemText">三角形</span>
                    </div>
                </Menu.Item> */}
            </Menu>
        )
    }

    renderEditMenu() {
        let {clearBdHd, clearAllHd} = this.props
        return (
            <Menu className="bd_customMenu">
                <Menu.Item>
                    <div className="menuItem" onClick={e => this.editCopyHd()}>
                        <span className="itemIcon"><CopyIcon /></span>
                        <span className="itemText">复<span style={{paddingLeft: 28}}></span>制</span>
                    </div>
                </Menu.Item>
                <Menu.Item>
                    <div className="menuItem" onClick={e => this.editDelHd()}>
                        <span className="itemIcon"><DelIcon /></span>
                        <span className="itemText">删<span style={{paddingLeft: 28}}></span>除</span>
                    </div>
                </Menu.Item>
                <Menu.Item>
                    <Popconfirm placement="rightBottom" title="请确认是否将当前白板画布内容清空" onConfirm={e => this.editClearHd()} okText="是" cancelText="否">
                        <div className="menuItem" onClick={undefined}>
                            <span className="itemIcon"><ClearIcon width={18}/></span>
                            <span className="itemText">清空画布</span>
                        </div>
                    </Popconfirm>
                </Menu.Item>
                <Menu.Item>
                    <Popconfirm placement="rightBottom" title="请确认是否将当前白板背景清空" 
                    onConfirm={e => clearBdHd ? clearBdHd() : undefined} okText="是" cancelText="否">
                        <div className="menuItem" onClick={undefined}>
                            <span className="itemIcon"><ClearIcon width={18}/></span>
                            <span className="itemText">清空背景</span>
                        </div>
                    </Popconfirm>
                </Menu.Item>
                <Menu.Item>
                    <Popconfirm placement="rightBottom" title="请确认是否将当前白板所有内容清空" 
                    onConfirm={e => clearAllHd ? clearAllHd() : undefined} okText="是" cancelText="否">
                        <div className="menuItem" onClick={undefined}>
                            <span className="itemIcon"><ClearIcon width={18}/></span>
                            <span className="itemText">清空所有</span>
                        </div>
                    </Popconfirm>
                </Menu.Item>
            </Menu>
        )
    }

    renderColorMenu() {
        const { color } = this.state
        return (
        <div className="bd_colorMenu">
            <ol className="colorOl">
            {colorsMenu.map((c, i) => {
                return (
                <li
                    key={i}
                    className="colorLi"
                    style={{ backgroundColor: c.color }}
                    onClick={() => this.setState({ color: c.name, colorVisible: true, })}
                >
                    {color === c.name ? <AuthIcon fill={c.color === '#ffffff' ? '#979797' : '#ffffff'}/> : null}
                </li>
                )
            })}
            </ol>
        </div>
        )
    }

    beforeUploadHd(file, fileList) {
        console.log("@#@#@: file = ", file)
        console.log("@#@#@: fileList = ", fileList)

        return true
    }

    renderSlideMenu() {
        let {getUploadParamsHd, uploadCbHd, prevSlideHd, nextSlideHd, customSelectHd, canDoSlideHd, uploadUrl, beforeUploadHd} = this.props
        if (canDoSlideHd !== undefined) {
            const canSlide = canDoSlideHd()
            if (canSlide === false){
                return (
                    <div></div>
                )
            }
        }

        return (
            <Menu className="bd_customMenu">
                <Menu.Item>
                    <Upload 
                        accept={supportFileTypes}
                        action={uploadUrl}
                        method="post"
                        showUploadList={false}
                        data={getUploadParamsHd}
                        beforeUpload={beforeUploadHd}
                        onChange={uploadCbHd}
                    >
                        <div className="menuItem" onClick={undefined}>
                            <span className="itemIcon"><UploadOutlined /></span>
                            <span className="itemText">新课件</span>
                        </div>
                    </Upload>
                </Menu.Item>
                <Menu.Item>
                    <div className="menuItem" onClick={e => prevSlideHd ? prevSlideHd() : undefined}>
                        <span className="itemIcon"><LeftOutlined /></span>
                        <span className="itemText">上一页</span>
                    </div>
                </Menu.Item>
                <Menu.Item>
                    <div className="menuItem" onClick={e => nextSlideHd ? nextSlideHd() : undefined}>
                        <span className="itemIcon"><RightOutlined /></span>
                        <span className="itemText">下一页</span>
                    </div>
                </Menu.Item>
                <Menu.Item>
                    <div className="menuItem" onClick={e => customSelectHd ? customSelectHd() : undefined}>
                        <span className="itemIcon"><EllipsisOutlined /></span>
                        <span className="itemText">自选页</span>
                    </div>
                </Menu.Item>

            </Menu>
        )
    }


    _calcBoardWidthAndHeight(clientWidth, clientHeight) {
        let {isView} = this.props
        let srcWidth = clientWidth
        let srcHeight = clientHeight - editBarHeight
        if (isView) {
            srcHeight = clientHeight
        }
        
        const factor = CANVAS_WH_FACTOR
        let result = {
            width: srcWidth, 
            height: srcHeight
        }

        let wFactor = srcWidth / srcHeight
        if (wFactor > factor) {
            // 以高度为准计算
            result.height = srcHeight
            result.width = srcHeight * factor 
        }else {
            // 以宽度为准计算
            result.width = srcWidth
            result.height = srcWidth / factor 
        }

        result.width.toFixed(0)
        result.height.toFixed(0)

        return result
    }

    mkEditTools() {
        let {color, textDsiplay, textDisplayX, textDisplayY, textContent, canUndo, canRedo} = this.state
        
        return (
            <div className="bd_editBar">
                    <div className="barCenter">
                        <EditBtn type="select" text="选择" selected={this.getBtnSelectFlag("select")} onClick={this.selectOnClickHd} active={true} />
                        <Dropdown overlay={this.renderStrokeMenu()} placement="bottomCenter" trigger={['hover']}>
                            <div><EditBtn type="bold" text="粗细" arrow selected={undefined} active={true} /></div>
                        </Dropdown>

                        <Dropdown overlay={this.renderShapeMenu()} placement="bottomCenter" trigger={['hover']}>
                            <div><EditBtn type="shape" text="形状" arrow selected={this.getBtnSelectFlag("shape")} active={true} /></div>
                        </Dropdown>

                        <Dropdown overlay={this.renderColorMenu()} placement="bottomCenter" trigger={['hover']}>
                            <div><EditBtn type="color" text="颜色" color={color} arrow selected={undefined}/></div>
                        </Dropdown>

                        <EditBtn type="text" text="文本" selected={this.getBtnSelectFlag("text")} active={true} onClick={this.textToolOnHd} />

                        <Dropdown overlay={this.renderEditMenu()} placement="bottomCenter" trigger={['hover']}>
                            <div><EditBtn type="edit" text="编辑" arrow selected={undefined} /></div>
                        </Dropdown>

                        {/* <EditBtn type="import" text="导入" selected={undefined} onClick={undefined} /> */}

                        <Dropdown overlay={this.renderSlideMenu()} placement="bottomCenter" trigger={['hover']}>
                            <div><EditBtn type="slide" text="课件" arrow selected={undefined} /></div>
                        </Dropdown> 

                        <EditBtn type="undo" text="撤销" onClick={this.historyUndoHd} active={canUndo}/>
                        <EditBtn type="redo" text="恢复" onClick={this.historyReduHd} active={canRedo}/>

                        {/* <EditBtn type="save" text="保存" onClick={this.saveHd} active={true}/> */}
                    </div>

                    <BdTextArea 
                        textValueOnChangeHd={this.textValueOnChangeHd}
                        display={textDsiplay}
                        textValue={textContent}
                        setX={textDisplayX}
                        setY={textDisplayY}
                        textAddHd={this.textAddHd}
                        textCancelHd={this.textCancelHd}
                    />
                        
                </div>
        )
    }

    mkEditTools2() {
        let {color, textDsiplay, textDisplayX, textDisplayY, textContent, canUndo, canRedo} = this.state;
        let {hideSlide} = this.props
        let {clientWidth, clientHeight} = document.documentElement
        let tmpNumber = (clientWidth/64).toFixed(0) - 1;
        let divWidth = 600;
        if (clientWidth < divWidth){
            divWidth = clientWidth;
        }

        if (tmpNumber > 9) {
            tmpNumber = 9;
        }


        const slideSettings = {
            // dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: tmpNumber,
            slidesToScroll: 3, 
           
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 9,
                    slidesToScroll: 3,
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 6,
                    slidesToScroll: 3,
                  }
                },
                {
                  breakpoint: 420,
                  settings: {
                    slidesToShow: 4,
                    slidesToScroll: 2
                  }
                }
              ]
        }

        return(
                <div className="bd_editBar">

                <div style={{textAlign: "center", marginTop: '6px', marginLeft: "auto", marginRight: "auto", maxWidth: '600px'}}>
                    <Slider {...slideSettings}>
                        
                        <EditBtn type="select" text="选择" selected={this.getBtnSelectFlag("select")} onClick={this.selectOnClickHd} active={true} />
                        
                        <Dropdown overlay={this.renderStrokeMenu()} placement="bottomCenter" trigger={['hover']}>
                            <div><EditBtn type="bold" text="粗细" arrow selected={undefined} active={true} /></div>
                        </Dropdown>
                        
                        <Dropdown overlay={this.renderShapeMenu()} placement="bottomCenter" trigger={['hover']}>
                            <div><EditBtn type="shape" text="形状" arrow selected={this.getBtnSelectFlag("shape")} active={true} /></div>
                        </Dropdown>
                        
                        <Dropdown overlay={this.renderColorMenu()} placement="bottomCenter" trigger={['hover']}>
                            <div><EditBtn type="color" text="颜色" color={color} arrow selected={undefined}/></div>
                        </Dropdown>

                        <EditBtn type="text" text="文本" selected={this.getBtnSelectFlag("text")} active={true} onClick={this.textToolOnHd} />

                        <Dropdown overlay={this.renderEditMenu()} placement="bottomCenter" trigger={['hover']}>
                            <div><EditBtn type="edit" text="编辑" arrow selected={undefined} /></div>
                        </Dropdown>

                        {hideSlide ? undefined : <Dropdown overlay={this.renderSlideMenu()} placement="bottomCenter" trigger={['hover']}>
                            <div><EditBtn type="slide" text="课件" arrow selected={undefined} /></div>
                        </Dropdown>}

                        <EditBtn type="undo" text="撤销" onClick={this.historyUndoHd} active={canUndo}/>
                        <EditBtn type="redo" text="恢复" onClick={this.historyReduHd} active={canRedo}/>

                        {/* <LeftOutlined /> */}
                    </Slider>

                    <BdTextArea 
                        textValueOnChangeHd={this.textValueOnChangeHd}
                        display={textDsiplay}
                        textValue={textContent}
                        setX={textDisplayX}
                        setY={textDisplayY}
                        textAddHd={this.textAddHd}
                        textCancelHd={this.textCancelHd}
                    />
                    
                </div>
                    
                </div>

        );
    }


    wheelHd(e) {
        console.log("#@#@#: e = ", e)

        if (this._sketch) {
            this._sketch.wheelHd(e)
        }
        
    }

    render(){
        let {color, lineWidth, currentTool, undoSteps, textDsiplay, textDisplayX, textDisplayY, textContent} = this.state
        let {mqttPublishHd, isView} = this.props

        let {clientWidth, clientHeight} = document.documentElement
        let canvasInfo = this._calcBoardWidthAndHeight(clientWidth, clientHeight)

        let displayTools = this.mkEditTools2();
        let padClass = undefined
        if (isView) {
            displayTools = undefined
            padClass = "sketchPad2"
        }
        
        return (
            <div>
                
                <div className="bd_whiteBoard">
                {displayTools}
                        
                <SketchPad 
                    ref={c => (this._sketch = c)}
                    lineWidth={lineWidth}
                    lineColor={color}
                    tool={currentTool}
                    undoSteps={undoSteps}
                    textToolMouseDownHd={this.textToolMouseDownHd}
                    // value={InitData}
                    dataChangHd={mqttPublishHd}
                    width={canvasInfo.width}
                    height={canvasInfo.height}
                    padClass={padClass}
                    
                />
                   
            </div>
            </div>
            
        );
    }
}

