



export const findItemByIdFromList = (findId, key, itemList) => {
    let result = undefined;
    for (let i in itemList){
        let item = itemList[i];
        if (findId === item[key]){
            result = {
                index: i, 
                item: item
            }
        }
    }
    return result;
}



