
// export const appHost = "http://angliancd.com:8038"
// export const mntnHost = "http://angliancd.com:8036"
export const appHost = "https://vboardapi.angliancd.com:3592"
export const mntnHost = "https://vboardapi.angliancd.com:3592"
// mqtt 信息
// export const mqttHost = "angliancd.com"
// export const mqttPort = 61614
// export const mqttHost = "angliancd.com/websocket"
export const mqttHost = "test.ludiqiao.com"
export const mqttPort = 61615
export const mqttUsername = "i4hBWxTe"
export const mqttPassword = "998eec2e7bb347ed9e49cdf64cc3d689"

// const host = 'http://angliancd.com:8036';

export const sendRequest = (path, body, method='POST', absoluteUrl=undefined, headers={}) => {
    const request = {
        method: method, 
        // mode: 'no-cors', 
        headers: Object.assign({
            'Accept': 'application/json', 
            'Content-Type': 'text/plain',
        }, headers), 
        body: JSON.stringify(body)
    };

    let dstUrl = mntnHost + path
    if (absoluteUrl !== undefined) {
        dstUrl = absoluteUrl
    }
    
    return fetch(dstUrl, request)
        .then(
            ((response) => {
                // console.log(response.json())
                return response.ok ? response.json() : Promise.reject(response.text());
            }), 
            ((errer) => {
                return Promise.reject(errer)
            })
        )
        .then(
            ((json) => {
                // console.log(json);
                // return {msg: json}
                return json;
            }), 
            ((error) => {
                console.log('then error = ', error);
                return undefined;
            })
        )
        .catch(
            (error) => {
                console.log('catch error = ', error);
                return undefined;
            }
        )
}

