import React, { Component, PureComponent } from 'react'
import { Card, List, Row, Col} from 'antd'

import { FixedSizeList } from 'react-window';
import {List as BList} from 'react-virtualized';


export class SlidePreview extends Component {
    constructor(props){
        super(props)

        this.pageOnClickHd = this.pageOnClickHd.bind(this)
        this.getCurrentPage = this.getCurrentPage.bind(this)
        this.updatePageList = this.updatePageList.bind(this)
        this.mkListContent = this.mkListContent.bind(this)
        this.mkListContent3 = this.mkListContent3.bind(this)
        this._rowRender = this._rowRender.bind(this)

        let {fileName, pageNum, currentPage, pageList} = props

        this.state = {
            currentPage: currentPage, 
            pageNum: pageNum, 
            fileName: fileName, 
            displayList: pageList, 
            // displayList: displayList
            // 暂时全部都加载
            // displayList: pageList
        }
        this._list = undefined
        
    }

    pageOnClickHd(selectPage) {
        let newState = Object.assign({}, this.state);
        newState.currentPage = selectPage;
        this.setState(newState);
        if (this._list) {
            this._list.forceUpdateGrid()
        }
    }

    componentDidMount() {
        let {currentPage} = this.state
        if (this._list) {
            if (currentPage >= 1) {
                // this._list.scrollToRow(currentPage - 1) 
            }
        }
    }

    getCurrentPage() {
        return this.state.currentPage
    }

    calcSlideHeight() {
        let {clientHeight} = document.documentElement
        let viewHeight = 0.75 * clientHeight
        viewHeight = viewHeight.toFixed(0) - 20
        // let setHeight = viewHeight + "px"
        return viewHeight
    }

    updatePageList(pageList) {
        if (pageList === undefined) {
            return 
        }

        let {displayList} = this.state

        if (displayList.length !== pageList.length) {
            // 需要更新内容
            let newState = Object.assign({}, this.state)
            newState.displayList = pageList
            newState.pageNum = displayList.length
            this.setState(newState)
        }
    }

    mkListContent() {
        let {currentPage, fileName, displayList} = this.state

        let listItem = undefined
        if (displayList) {
            listItem = displayList.map((item, index) => {
                    const displayPageNum = (
                        <Row>
                            <Col span={3}/>
                            <Col span={6}>第{index+1}页</Col>
                            <Col span={3}/>
                        </Row>
                    )

                    let style = undefined
                    if ((index+1) === currentPage) {
                        style = {boxShadow: "0px 4px 12px rgba(0, 39, 102,.2)"}
                    }

                    return (
                        <List.Item key={index} >
                            <div style={style}>
                            <Card hoverable cover={<img alt="preview" className="bd_courseware_preview_img" src={item}/>} 
                                onClick={e => this.pageOnClickHd(index+1)} >
                                <Card.Meta title={displayPageNum} />
                            </Card>
                            </div>
                        </List.Item>
                    )
                }
            );
        }

        return (
            <div className="bd_slide_preview">
                <List itemLayout="vertical"  >
                    {listItem}
                </List>
            </div>
        )

    }

    getRowHeight() {
        let setHeight = this.calcSlideHeight()
        let rowHeight = setHeight * 0.5 - 20
        // let rowHeight = setHeight * 0.5
        rowHeight.toFixed(0)
        return rowHeight
    }

    mkListContent3() {
        let {currentPage, pageNum} = this.state
        
        let selectIndex = 0
        if (currentPage >= 1) {
            selectIndex = currentPage - 1
        }

        let rowHeight = this.getRowHeight()

        return (
            <BList 
                rowCount={pageNum}  
                ref={c => (this._list = c)}
                height={2*rowHeight}
                width={420}
                rowHeight={rowHeight}
                rowRenderer={this._rowRender}
                scrollToIndex={selectIndex}
                >
            </BList>
        )


    }

    _rowRender(srcData) {
        let {index, key, style} = srcData
        let {currentPage, displayList} = this.state

        const item = displayList[index]
        const displayPageNum = (
            <Row>
                <span>第{index+1}页</span>
            </Row>
        )

        let shadow = ""
        if ((index+1) === currentPage) {
            shadow = "0px 4px 12px rgba(0, 39, 102,.2)"
        }

        return (
            <div style={style} key={key} >
                <div style={{textAlign: "center", height: "100%", marginRight: "2px", marginLeft: "12px"}}>
                <Row >
                    <img style={{height: this.getRowHeight() - 32, border: "1px solid #e8e8e8", borderRadius: "8px", boxShadow: shadow}} 
                    src={item}
                    onClick={e => this.pageOnClickHd(index+1)}
                    >
                    </img>
                </Row>
            
                {displayPageNum}
                </div>

            </div>
            
            
        );
    }

    render() {
        let {currentPage, fileName} = this.state
        let setHeight = this.calcSlideHeight()
        let listInfo = this.mkListContent3()

        return (
            <div style={{height: setHeight}}>
                <Row style={{marginTop: "10px", marginBottom: "5px"}}>
                    <Col span={8}>{fileName}</Col>
                    <Col span={6}>显示第
                    <span style={{marginLeft: "3px", marginRight: "3px", fontSize: 20, color: "orange", fontWeight: "500"}}>
                        {currentPage}
                    </span>页</Col>
                </Row>

                {listInfo}
                    
            </div>
        );
    }
}


