'use strict'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Spin, Modal, Row} from 'antd'
import {WhiteBoard} from './whiteBoard'
import {uuid4} from '../../component/board/tools'
import {SlidePreview} from './slidePreview'
import {mqttHost, mqttPort, mqttUsername, mqttPassword, appHost, mntnHost} from '../../comm/net'
import {AlMessage} from '../../component/comm/utils'
import {supportFileTypes} from '../../comm/config'
import {getNetRequestStatus, netRequestThunk, actNetRequestClear} from '../../comm/netReqRD'
import {getCourseware, actSetBoardCourseware, getCoursewareSuccessHd, getCoursewareFailedHd} from './boardRD'

// getUserInfo
var mqtt = require('mqtt')

class LogicBoard extends Component {
    constructor(props){
        super(props);

        this.mqttSubCb = this.mqttSubCb.bind(this)
        this.mqttPublishHd = this.mqttPublishHd.bind(this)
        this.getUploadParamsHd = this.getUploadParamsHd.bind(this)
        this.uploadCbHd = this.uploadCbHd.bind(this)
        this.displayUploadingUi = this.displayUploadingUi.bind(this)
        this.displayCustomPageUi = this.displayCustomPageUi.bind(this)
        this.getCurrentDisplayCoursewarePage = this.getCurrentDisplayCoursewarePage.bind(this)
        this._setBd = this._setBd.bind(this)
        this._clearBoardContent = this._clearBoardContent.bind(this)
        this.prevSlideHd = this.prevSlideHd.bind(this)
        this.nextSlideHd = this.nextSlideHd.bind(this)
        this.bdPublishHd = this.bdPublishHd.bind(this)
        this._makeCoursewarePreviewList = this._makeCoursewarePreviewList.bind(this)
        this.selectSlidePageHd = this.selectSlidePageHd.bind(this)
        this.keyUpHd = this.keyUpHd.bind(this)
        this.canDoSlideHd = this.canDoSlideHd.bind(this)
        this.canDoDraw = this.canDoDraw.bind(this)
        this.beforeUploadHd = this.beforeUploadHd.bind(this)
        this.updatePrivaCourseware = this.updatePrivaCourseware.bind(this)
        this.refreshCoursewareTask = this.refreshCoursewareTask.bind(this)
        this.refreshSuccessCb = this.refreshSuccessCb.bind(this)
        this.clearBdHd = this.clearBdHd.bind(this)
        this.clearAllHd = this.clearAllHd.bind(this)
        this.wheelHd = this.wheelHd.bind(this)
        
        let {boardId, project} = props;
        

        // 生成本页的onwerId
        const boardOwner = uuid4()
        const boardTopic = "cs/alboard/content/" + project + "/conference/" + boardId
        const bgTopic = "cs/alboard/backgroud/" + project + "/conference/" + boardId

        // 私有信息初始化
        this._boardContent = undefined
        this._boardBgUrl = undefined
        this._isFirstSubBg = true
        this._courseware = undefined
        this._uploadState = "tostart"
        this._uploadingModel = undefined
        this._customPageModal = undefined
        this._slidePreview = undefined
        
        this.state = {
            boardOwner: boardOwner, 
            boardId: boardId, 
            boardTopic: boardTopic, 
            bgTopic: bgTopic, 
        };

    }

    componentWillUnmount(){
        let {dispatch} = this.props

        if (this.mqttClient) {
            this.mqttClient.unsubscribe()
            this.mqttClient.end()
        }

        if (this._customPageModal) {
            this._customPageModal.destroy()
        }

        if (this._uploadingModel) {
            this._uploadingModel.destroy()
        }

        document.removeEventListener('keyup', this.keyUpHd);  

        dispatch(actNetRequestClear("getPagesBd"))

    }

    componentDidMount() {
        let {boardTopic, bgTopic, boardId} = this.state
        let {userInfo, tokenInfo, dispatch} = this.props

        // 监听按键事件
        document.addEventListener('keyup', this.keyUpHd);        
        document.addEventListener('wheel', this.wheelHd);    
         
        // 创建mqtt连接
        const opt = {
            host: mqttHost, 
            port: mqttPort, 
            hostname: mqttHost, 
            password: mqttPassword, 
            username: mqttUsername, 
            clientId: "board_client_" + uuid4(), 
            protocol: 'wss'
        }

        let client = mqtt.connect('', opt)
        client.subscribe(boardTopic)
        client.subscribe(bgTopic)

        console.log("@@@@@@: opt = , boardTopic = ", opt, boardTopic)

        client.on('message', this.mqttSubCb)
        this.mqttClient = client

        setTimeout(()=>
        {
            this._isFirstSubBg = false
        }, 3000)

    }

    

    keyUpHd(e) {
        switch(e.key) {
            case "ArrowUp":
            case "ArrowLeft":
                // 前一页
                this.prevSlideHd()
                break;

            case "ArrowDown":
            case "ArrowRight":
                // 后一页
                this.nextSlideHd()
                break;

            case "Delete":
                // 删除已经选中的物体
                this._board.editDelHd();
                break;

            default:
                break;
        }


    }

    wheelHd(e) {
        if (this._customPageModal) {
            return;
        }

        if (this._board) {
            this._board.wheelHd(e)
        }
    }


    mqttSubCb(topic, message) {
        let {boardTopic, bgTopic, boardOwner, boardId} = this.state
        let {dispatch} = this.props
        if (topic !== boardTopic && topic !== bgTopic) {
            return
        }

        const parseData = JSON.parse(message)
        if (parseData.BoardOwner === boardOwner) {
            return 
        }

        if (topic === boardTopic) {
            // 画板内容改变通知
            if (parseData.BoardContent !== this._boardContent) {
                this._board.loadObjsFromJson(parseData.BoardContent, parseData.BoardWidth, parseData.BoardHeight)
                this._boardContent = parseData.BoardContent
            }
        }else if (topic === bgTopic) {
            // 背景改变通知
            if (this._boardBgUrl !== parseData.BgUrl) {
                let {BoardWidth, BoardHeight} = parseData
                let canvansInfo = {
                    width: BoardWidth, 
                    height: BoardHeight
                }

                // 需要确定是否清除画板内容
                if (this._isFirstSubBg) {
                    // 第一次，不需要清除
                    this._isFirstSubBg = false
                    this._setBd(parseData.BgUrl, false, canvansInfo=canvansInfo)
                }else{
                    this._setBd(parseData.BgUrl, canvansInfo=canvansInfo)
                }
                this._boardBgUrl = parseData.BgUrl
            }
        }

    }

    mqttPublishHd(content, width, height) {
        let {boardTopic, boardId, boardOwner} = this.state

        let pubBody = {
            Type: 0, 
            MsgCode: 15000, 
            ConfSipCode: boardId, 
            BoardWidth: width, 
            BoardHeight: height, 
            BoardOwner: boardOwner, 
            BoardContent: content
        }

        this._boardContent = pubBody.BoardContent
        const strBody = JSON.stringify(pubBody)
        this.mqttClient.publish(boardTopic, strBody, {qos: 0, retain: true})
    }

    bdPublishHd(url) {
        let canvasInfo = this._board.getBoardCanvasInfo()
        if (url === undefined || canvasInfo === undefined) {
            
            return
        }

        let {bgTopic, boardId, boardOwner} = this.state
        let pubBody = {
            Type: 0, 
            MsgCode: 15001, 
            ConfSipCode: boardId, 
            BoardOwner: boardOwner, 
            BgUrl: url,
            BoardWidth: canvasInfo.width, 
            BoardHeight: canvasInfo.height,
        }

        const strBody = JSON.stringify(pubBody)
        this.mqttClient.publish(bgTopic, strBody, {qos: 0, retain: true})
    }

    getUploadParamsHd() {
        let {boardId} = this.state
        return {
            userToken: "aaabbbccc", 
            confSipCode: boardId
        }
    }

    unsupportFileHint(extName) {
        return (
            <div>
                <Row style={{marginBottom: 5, marginTop: 5}}>
                <span>不支持的文件类型： <span style={{color: "#f5222d", fontSize: 16, fontWeight: 400}}>{extName}</span>!</span>
                </Row>
                <Row>
                    <span>支持的文件类型为：  <span style={{color: "#2f54eb", fontSize: 16, fontWeight: 300}}>{supportFileTypes}</span></span>
                </Row>
            </div>
        )
    }

    beforeUploadHd(file, fileList) {
        let fileName = file.name

        let tmpStrs = fileName.split(".")
        if (tmpStrs.length > 0){
            const extName = tmpStrs[tmpStrs.length - 1]
            let index = supportFileTypes.indexOf(extName)
            if (index === -1) {
                AlMessage("info", this.unsupportFileHint(extName), 4)
                return false
            }
        }

        this.displayUploadingUi('uploading')
        this._uploadState = 'uploading'
        return true
    }

    refreshCoursewareTask() {
        let {courseware, project, dispatch} = this.props
        if (courseware === undefined) {
            return 
        }

        let {coursewareId, pageNum, pageList} = courseware
        if (pageNum <= pageList.length) {
            // 列表中数目已经大于等于总页数后，不用继续任务
            return
        }

        // 向服务端请求数据
        let msgBody = {
            coursewareId: coursewareId, 
            pageNum: pageNum, 
        }

        if (project === "ldq") {
            dispatch(netRequestThunk("/upload/ldq/courseware/getPagesBd", msgBody, "getPagesBd", getCoursewareSuccessHd, getCoursewareFailedHd, this.refreshSuccessCb))
        }else{
            dispatch(netRequestThunk("/upload/online/courseware/getPagesBd", msgBody, "getPagesBd", getCoursewareSuccessHd, getCoursewareFailedHd, this.refreshSuccessCb))
        }
        
        setTimeout(this.refreshCoursewareTask, 1000*3)
    }

    refreshSuccessCb(courseware) {
        if (courseware === undefined) {
            return 
        }

        let {pageList} = courseware

        this._courseware.pageNum = pageList.length
        this._courseware.pageList = pageList

        if (this._slidePreview) {
            this._slidePreview.updatePageList(courseware.pageList)
        }

    }

    uploadCbHd(info) {
        let {file} = info
        if (file === undefined) {
            return
        }
        
        switch(file.status) {
            case "uploading":
                break;

            case "error":
                if (this._uploadState === 'uploading') {
                    this.displayUploadingUi('uploadFailed')
                    this._uploadState = 'uploadFailed'
                }
                break;

            case "done":
                // 解析反馈内容，然后展示选择课件页面UI
                if (this._uploadingModel !== undefined){
                    this._uploadingModel.destroy()
                }

                // 先处理正常场景，后面再处理异常
                if (file.response === undefined){
                    // 异常场景后面处理
                    return
                }

                let {status, statusMsg, fileName, pageNum, pageList, coursewareId} = file.response
                if (status !== 0) {
                    // 异常场景后面处理
                    AlMessage("error", statusMsg)
                    return
                }

                if (pageNum < 1){
                    // 异常场景后面处理
                    return
                }

                
                    

                // 保存当前course信息
                let propsCourseware = {
                    coursewareId: coursewareId, 
                    pageNum: pageNum, 
                    pageList: pageList
                }

                let {dispatch} = this.props
                dispatch(actSetBoardCourseware(propsCourseware))

                if (pageNum !== pageList.length) {
                    pageNum = pageList.length
                    // 启动定时任务更新课件内容信息, 3s刷一次
                    setTimeout(this.refreshCoursewareTask, 1000*3)
                }

                const courseware = {
                    // coursewareId: coursewareId, 
                    fileName: fileName, 
                    pageNum: pageNum, 
                    currentPage: 0, 
                    pageList: pageList
                }

                this._courseware = courseware
                this._uploadState = "tostart"

                if (pageNum === 1) {
                    // 只有一页的，直接显示
                    this._courseware.currentPage = 1
                    const bgUrl = this.getCurrentDisplayCoursewarePage(this._courseware)
                    this._setBd(bgUrl)
                    this.bdPublishHd(bgUrl)
                }else{
                    // 展示选择页面
                    this.displayCustomPageUi()
                }                
                break;

            default:
                break;

        }

    }

    // 文件上传过程指示UI
    displayUploadingUi(uploadState) {
        let display = false
        let disContent = undefined

        if (uploadState === "uploading") {
            display = true
            disContent = (
                <Spin tip="Uploading...">
                    <div style={{fontSize: 16, fontWeight: 400, color: 'blue'}}>
                        <span>
                        课件上传中，可能需要十几秒钟, 请耐心等待......
                        </span>
                    </div>
                </Spin>
            )

        } else if (uploadState === "uploadFailed") {
            display = true
            disContent = (
                <div style={{fontSize: 16, fontWeight: 400, color: 'orange'}}>
                    <span>
                        上传失败，请检查网络后，稍后再试！
                    </span>
                </div>
            )
        }

        if (display) {
            if (this._uploadingModel !== undefined) {
                this._uploadingModel.destroy()
            }
            this._uploadingModel = Modal.warning(
                {
                    title: "课件上传", 
                    content: disContent, 
                    okText: "关闭", 
                    onOk: undefined, 
                    destroyOnClose: true, 
                }
            ) 
        }
    }

    updatePrivaCourseware() {
        if (this._courseware === undefined) {
            return 
        }

        let {courseware} = this.props
        if (courseware !== undefined) {
            let propsPageLen = courseware.pageList.length
            let {pageNum, pageList} = this._courseware
            let privaPagelen = pageList.length

            if (propsPageLen !== privaPagelen) {
                pageNum = propsPageLen
                pageList = courseware.pageList
                this._courseware.pageNum = pageNum
                this._courseware.pageList = pageList
            }
        }
    }

    // 自选课件背景UI
    displayCustomPageUi() {
        this.updatePrivaCourseware()

        let disContent = this._makeCoursewarePreviewList()
        if (disContent === undefined) {
            return
        }

        if (this._customPageModal !== undefined) {
            this._customPageModal.destroy()
        }

        this._customPageModal = Modal.confirm(
            {
                title: "请选择课件当前显示页！", 
                content: disContent, 
                okText: "确定", 
                cancelText: "取消", 
                centered: true, 
                width: 540, 
                onOk: this.selectSlidePageHd, 
                destroyOnClose: true, 
            }
        )
    }

    // 自动选择课件完成回调
    selectSlidePageHd() {
        if (this._courseware === undefined) {
            return
        }

        if (this._slidePreview) {
            const currentPage = this._slidePreview.getCurrentPage()
            if (currentPage === this._courseware.currentPage) {
                // 什么也不做
                return
            }

            this._courseware.currentPage = currentPage

            // 设置显示内容
            const url = this.getCurrentDisplayCoursewarePage(this._courseware)
            this._setBd(url)
            this.bdPublishHd(url)
            if (this._customPageModal) {
                this._customPageModal.destroy()
                this._customPageModal = undefined
            }
            
        }
    }

    _makeCoursewarePreviewList() {
        if (this._courseware === undefined) {
            return undefined
        }

        let {fileName, pageNum, currentPage, pageList} = this._courseware
        if (currentPage === 0) {
            currentPage = 1
        }

        let {courseware} = this.props

        return (
            <SlidePreview 
                ref={c => (this._slidePreview = c)}
                fileName={fileName}
                pageNum={pageNum}
                currentPage={currentPage}
                pageList={pageList}
                courseware={courseware}
                />
        )
    }

    getCurrentDisplayCoursewarePage(courseware) {
        if (courseware === undefined) {
            return undefined
        }

        let {fileName, pageNum, currentPage, pageList} = courseware
        if (currentPage === 0) {
            return undefined
        }

        return pageList[currentPage - 1]
    }

    _setBd(url, isClear=true, canvansInfo=undefined) {
        if (url === undefined) {
            return
        }
        this._board.setBackgroundImageFromUrl(url, canvansInfo=canvansInfo)
        if (isClear){
            this._clearBoardContent()
        }
    }

    _clearBoardContent() {
        this._board.clearBoardContent()
    }

    prevSlideHd() {
        const canDo = this.canDoSlideHd()
        if (!canDo) {
            return
        }

        let courseware = this._courseware
        if (courseware === undefined) {
            return
        }
        let {pageNum, currentPage} = courseware

        if (currentPage === 1) {
            // 需要提示一下已到第一页
            return
        }

        courseware.currentPage = currentPage - 1
        const bgurl = this.getCurrentDisplayCoursewarePage(courseware)
        // this._setBd(bgurl)
        this._setBd(bgurl)
        this._clearBoardContent()
        this.bdPublishHd(bgurl)

        this._courseware = courseware
    }

    nextSlideHd() {
        const canDo = this.canDoSlideHd()
        if (!canDo) {
            return
        }
        let courseware = this._courseware
        if (courseware === undefined) {
            return
        }
        let {pageNum, currentPage} = courseware

        if (currentPage === pageNum) {
            // 需要提示一下已到最后一页
            return
        }

        courseware.currentPage = currentPage + 1
        const bgurl = this.getCurrentDisplayCoursewarePage(courseware)
        this._setBd(bgurl)
        this.bdPublishHd(bgurl)
        this._courseware = courseware
    }

    clearAllHd() {
        this._setBd("")
        this._clearBoardContent()
        this.bdPublishHd("")
    }

    clearBdHd() {
        this._setBd("", false)
        this.bdPublishHd("")
    }

    canDoSlideHd() {
        let {isMaster} = this.props
        if (isMaster) {
            return true
        }

        return false
    }

    canDoDraw() {
        let {isView} = this.props;

        if (isView) {
            return false
        }

        return true
    }


    render(){
        let {isView, uploadUrl} = this.props
        const hideSlide = !this.canDoSlideHd()
    
        return (
            <div>
                <WhiteBoard 
                    ref={c => (this._board = c)}
                    mqttPublishHd={this.mqttPublishHd}
                    bdPublishHd={this.bdPublishHd}
                    getUploadParamsHd={this.getUploadParamsHd}
                    uploadCbHd={this.uploadCbHd}
                    prevSlideHd={this.prevSlideHd}
                    nextSlideHd={this.nextSlideHd}
                    customSelectHd={this.displayCustomPageUi}
                    canDoSlideHd={this.canDoSlideHd}
                    beforeUploadHd={this.beforeUploadHd}
                    clearBdHd={this.clearBdHd}
                    clearAllHd={this.clearAllHd}
                    isView={isView}
                    hideSlide={hideSlide}
                    uploadUrl={uploadUrl}
            
                />
            </div>
        );
    }
}


const mapState = (state) => ({
   courseware: getCourseware(state), 
});

export default connect(
    mapState, 
    null
)(LogicBoard);


