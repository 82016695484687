import React, { Component } from 'react'
import { connect } from 'react-redux'
import LogicBoard from '../board/logicBoard'
import {ldqUploadUrl} from '../../comm/config'


class LdqMasterCT extends Component {
    constructor(props){
        super(props);

        let {match} = props;

        this._boardId = undefined
        if (match.params.boardId !== undefined) {
            this._boardId = match.params.boardId
        }

       
    }

    render() {
        return (
            <LogicBoard 
                isMaster={true}
                isView={false}
                boardId={this._boardId}
                uploadUrl={ldqUploadUrl}
                project="ldq"
            />
        );
    }

}


const mapState = (state) => ({
   
    
});

export default connect(
    mapState, 
    null
)(LdqMasterCT);

