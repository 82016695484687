import { message, Button } from 'antd';


export const AlMessage = (type, msg, duration=3, onClose=undefined) => {
    let fun = undefined;
    switch(type){
        case "success":
            fun = message.success;
            break;
        case "info":
            fun = message.info;
            break;
        case "error":
            fun = message.error;
            break;
        case "warning":
            fun = message.warning;
            break;
        case "loading":
            fun = message.loading;
            break;
        default:
            break;
    }
    fun(msg, duration, onClose);
}


