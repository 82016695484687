export const appMode = "demo"
// export const appMode = "product"
export const uploadUrl = "https://board.angliancd.com/upload/courseware"
export const uploadBaseUrl = "https://board.angliancd.com/upload"
export const ldqUploadUrl = "https://vboardapi.angliancd.com:3592/upload/ldq/courseware"
export const onlineUploadUrl = "https://vboardapi.angliancd.com:3592/upload/online/courseware"


export const supportFileTypes = ".jpg,.png,.pdf,.ppt,.pptx,.doc,.docx";

