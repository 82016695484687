

export const colorsMenu = [
    { name: 'black', color: '#5D7092' },
    { name: 'red', color: '#E8684A' },
    { name: 'orange', color: '#FF9D4D' },
    { name: 'green', color: '#269A99' },
    { name: 'blue', color: '#5B8FF9' },
    { name: 'cyan', color: '#5AD8A6' },
    { name: 'purple', color: '#9270CA' },
    { name: 'white', color: '#ffffff' }
  ];

export const CANVAS_WH_FACTOR = 1.778

export const OP_TYPE = {
    SELECT: 'select',
    DRAW_CURVE: 'draw_curve', 
    DRAW_LINE: 'draw_line',
    DRAW_ARROW: 'draw_arrow', 
    DRAW_RECT: 'draw_rect', 
    DRAW_ELLIPSE: 'draw_ellipse', 
    DRAW_TRIANGLE: 'draw_triangle', 
    CLEAR: 'clear',
    CLEAR_ALL: 'clear_all',
    INSERT_PIC: 'insert_pic',
    TEXT: 'text',
    DROP_PIC: 'drop_pic',
    UNDO: 'undo',
    REDO: 'redo',
    MOVE: 'move',
    DRAG: 'drag'
}

export const LINE_WIDTH = {
    SMALL: 'small', 
    NORMAL: 'normal', 
    BIG: 'big',
}

export const editBarHeight = 64;

