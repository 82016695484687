import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux'


// ************************************************************
// 各模块路由表配置
// ************************************************************

// account
// import LoginCT from '../app/account/loginCT'

// board
import BoardLoadCT from '../app/board/loadCT'

import LdqMasterCT from '../app/ldq/masterCT'
import LdqAssistCT from '../app/ldq/assistCT'
import LdqViewCT from '../app/ldq/viewCT'

import OnlineMasterCT from '../app/online/masterCT'
import OnlineAssistCT from '../app/online/assistCT'
import OnlineViewCT from '../app/online/viewCT'

// test
import TestCT from '../app/test/testCT'

class AppRoute extends Component {
    
    render(){
        return (
            <Switch>
                {/* <Route path={`${this.props.match.path}`} exact component={LoginCT} /> */}

                <Route path={`${this.props.match.path}/load/:userInfo`} exact component={BoardLoadCT} />
                <Route path={`${this.props.match.path}/load/`} exact component={BoardLoadCT} />

                {/* 陆地桥白板 */}
                <Route path={`${this.props.match.path}/ludiqiao/master/:boardId`} exact component={LdqMasterCT} />
                <Route path={`${this.props.match.path}/ludiqiao/assist/:boardId`} exact component={LdqAssistCT} />
                <Route path={`${this.props.match.path}/ludiqiao/view/:boardId`} exact component={LdqViewCT} />

                {/* 昂联白板 */}
                <Route path={`${this.props.match.path}/online/master/:boardId`} exact component={OnlineMasterCT} />
                <Route path={`${this.props.match.path}/online/assist/:boardId`} exact component={OnlineAssistCT} />
                <Route path={`${this.props.match.path}/online/view/:boardId`} exact component={OnlineViewCT} />

                {/* test */}
                <Route path={`${this.props.match.path}/test`} exact component={TestCT} />

            </Switch>
        );
    }
}

export default connect(
    null, 
    null
)(AppRoute);

