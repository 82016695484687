import React from 'react'

export const AuthIcon = (props) => (
  <svg x="0px" y="0px" viewBox="0 0 24 24" style={{enableBackground: 'new 0 0 24 24'}} {...props}>
    <g>
      <g transform="translate(-752.000000, -239.000000)">
        <g transform="translate(563.000000, 200.000000)">
          <g transform="translate(0.000000, 28.000000)">
            <polygon points="198.7,32 190,24.6 192.7,21.4 198.3,26.2 208.9,14 212,16.7 				"/>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export const AmplificationIcon = (props) => (
  <svg x="0px" y="0px" viewBox="0 0 64 64" style={{enableBackground:'new 0 0 64 64'}} {...props}>
  <path  d="M49.6,52.2c-0.4,0-0.7-0.2-1-0.5l-9.9-9.9c-3,2.5-6.9,3.9-10.9,3.9c-9.4,0-17-7.6-17-17c0-9.4,7.6-17,17-17
  	c9.4,0,17,7.6,17,17c0,4-1.4,7.9-3.9,10.9l9.9,9.9c0.6,0.7,0.6,1.6-0.1,2.2C50.4,52.2,49.8,52.2,49.6,52.2z M27.8,14.9
  	c-7.7,0-13.9,6.2-13.9,13.9c0,7.7,6.2,13.9,13.9,13.9c7.7,0,13.9-6.2,13.9-13.9C41.7,21.1,35.5,14.9,27.8,14.9z"/>
  </svg>
)

export const ClearIcon = (props) => (
  <svg x="0px" y="0px" viewBox="0 0 64 64" style={{enableBackground:'new 0 0 64 64'}} {...props}>
    <path  d="M50,15.2H34.3v-2c0-0.8-0.8-1.7-2-1.7h-1.7c-1.2,0-2,0.8-2,1.7v2H13.1c-0.9,0-1.7,0.8-1.7,1.7
  	c0,0.9,0.8,1.7,1.7,1.7H16v29.1c0,2,1.7,3.7,3.7,3.7h23.7c2,0,3.7-1.7,3.7-3.7V18.7H50c0.9,0,1.7-0.8,1.7-1.7
  	C51.7,16,50.9,15.2,50,15.2z M43.4,46c0,1.2-0.8,1.7-1.7,1.7H21.4c-1.2,0-1.7-0.8-1.7-1.7V18.7h23.7V46L43.4,46z"/>
    <path  d="M36,46c1.2,0,1.7-0.8,1.7-1.7v-22c0-1.2-0.8-1.7-1.7-1.7c-1.2,0-1.7,0.8-1.7,1.7V44
    	C34.2,45.2,34.8,46,36,46z M26.8,46c1.2,0,1.7-0.8,1.7-1.7v-22c0-1.2-0.8-1.7-1.7-1.7c-1.2,0-1.7,0.8-1.7,1.7V44
    	C25.1,45.2,26,46,26.8,46z"/>
  </svg>
)

export const ColorIcon = (props) => (
  <svg x="0px" y="0px" viewBox="0 0 64 64" style={{enableBackground:'new 0 0 64 64'}} {...props}>
    <path  d="M57.6,20c-0.3-0.3-0.6-0.3-0.6-0.3c-0.6,0-1.5,0.6-2.1,1.2L35.3,39.5c-0.6,0.6-3.4,4.9-3.4,5.2l0,0
    c0.3,0,4.6-2.7,5.2-3.4l19.5-18.6C57.6,22.1,58.2,20.9,57.6,20L57.6,20z M45.4,25.8c0,1.5-1.2,2.7-2.7,2.7c-1.5,0-2.7-1.2-2.7-2.7
    s1.2-2.7,2.7-2.7C44.1,23,45.4,24.3,45.4,25.8L45.4,25.8z M15.1,35.6c-1.5,0-2.7-1.2-2.7-2.7s1.2-2.7,2.7-2.7s3.1,1.2,3.1,2.7
    S17,35.6,15.1,35.6L15.1,35.6z M19.4,25.8c0-1.5,1.2-2.7,2.7-2.7s2.7,1.2,2.7,2.7s-0.9,2.7-2.4,2.7C20.9,28.5,19.4,27.3,19.4,25.8
    L19.4,25.8z M29.5,23c0-1.5,1.2-2.7,2.7-2.7c1.5,0,2.7,1.2,2.7,2.7c0,1.5-1.2,2.7-2.7,2.7C30.7,25.8,29.5,24.6,29.5,23z"/>
    <path  d="M51.8,32.5c-1.2,8.2-9.2,16.8-20.2,16.8c-2.1,0-4.3-0.3-6.1-1.2c-1.8-0.6-2.7-1.5-3.1-2.1
    c0-0.3-0.3-0.6-0.3-0.9c-0.3-0.9-0.6-2.1-1.2-3.1c-0.9-1.8-2.4-2.1-3.7-2.1H16c-0.6,0.3-1.5,0.3-2.1,0.3c-1.5,0-4-0.3-4-2.7
    C9.6,24.6,20.3,17.6,31,16.3h2.4c3.1,0,6.4,0.6,8.9,1.5c0.6,0.3,0.9,0.3,2.1,0.9c1.5,0.6,2.7,1.8,3.7,2.7l1.8-3.1
    c-0.9-0.9-2.4-1.8-4-2.7c-1.5-0.6-1.8-0.9-2.4-1.2c-2.7-0.9-6.4-1.8-10.1-1.8h-2.7c-6.7,1.2-12.5,3.7-16.8,7.6
    C9,24.9,6.3,30.7,6.6,37.4c0,1.8,0.9,3.7,2.4,4.6c1.2,0.9,3.1,1.5,4.9,1.5c0.9,0,1.8,0,2.7-0.3h0.6c0.6,0,0.9,0.6,1.5,2.7
    c0,0.3,0.3,0.6,0.3,1.2c0.6,1.8,2.1,3.4,4.9,4.3c2.1,0.9,4.9,1.2,7.3,1.2c3.1,0,6.1-0.6,8.9-1.8c2.7-0.9,5.2-2.4,7.3-4.6
    c2.1-1.8,3.7-4,4.9-6.4c1.2-2.4,2.1-4.9,2.4-7.6v-0.6L51.8,32.5L51.8,32.5z"/>
  </svg>
)

export const EnterBoardIcon = (props) => (
  <svg x="0px" y="0px" viewBox="0 0 64 64" style={{enableBackground:'new 0 0 64 64'}} {...props}>
    <path  d="M9.6,52.2c-1.3,0-2.4-1.1-2.4-2.4V14.1c0-1.3,1.1-2.4,2.4-2.4h46.3c1.3,0,2.3,1,2.4,2.3l0,35.8
    c0,1.3-1.1,2.4-2.4,2.4H9.6z M9,48.5c0,1,0.8,1.8,1.8,1.8h43.9c1,0,1.8-0.8,1.8-1.8V22.1H9V48.5z M56.5,20.2v-5c0-1-0.8-1.8-1.8-1.8
    H10.8c-1,0-1.8,0.8-1.8,1.8v5H56.5z"/>
    <path  d="M10.8,16.6c0,0.5,0.2,0.9,0.6,1.1c0.4,0.2,0.9,0.2,1.3,0c0.4-0.2,0.6-0.6,0.6-1.1
    s-0.2-0.9-0.6-1.1c-0.4-0.2-0.9-0.2-1.3,0C11.1,15.7,10.8,16.2,10.8,16.6L10.8,16.6z"/>
    <path  d="M15.3,16.6c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3
    C15.9,15.3,15.3,15.9,15.3,16.6z"/>
    <path  d="M19.8,16.6c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3
    C20.3,15.3,19.8,15.9,19.8,16.6z"/>
    <path  d="M34.9,37.6c-1,0-1.8-0.4-2.5-1.1c-0.7-0.7-1-1.5-1-2.5c0-1.1,0.4-1.9,1.1-2.6l5.7-5.2c0.6-0.4,1.1-0.7,1.7-0.7
    c0.7,0,1.2,0.2,1.6,0.7c0.5,0.4,0.8,0.9,0.8,1.6c0,0.4-0.2,1-0.5,1.7c-2,3.8-3.4,6.1-4.3,7C36.7,37.3,35.9,37.6,34.9,37.6z
     M39.8,27.3c-0.2,0-0.4,0.1-0.6,0.2l-5.7,5.2C33.2,33.1,33,33.5,33,34c0,0.5,0.2,1,0.5,1.4c0.4,0.4,0.8,0.5,1.4,0.5
    c0.5,0,0.9-0.1,1.4-0.6c0.4-0.3,1.4-1.7,3.9-6.4c0.2-0.4,0.3-0.7,0.3-0.9c0-0.3,0-0.3-0.1-0.5C40.2,27.4,40,27.3,39.8,27.3z"/>
    <path  d="M28.6,44.4c-0.8,0-1.5-0.2-2.4-0.5c-0.6-0.3-2.3-1.1-2.9-4c-0.2-0.8-0.2-1.4,0.1-1.9c0.3-0.3,0.5-0.4,0.9-0.4
    c0.2,0,0.6,0.1,0.8,0.2l0.2,0.1c0.2,0.1,0.4,0.1,0.6,0.1c0.2,0,0.4,0,0.6-0.3c0.2-0.3,0.4-0.6,0.7-0.8c0.3-0.2,0.6-0.4,1.1-0.5
    c0.2-0.1,0.4-0.2,0.7-0.2c0.1,0,0.2,0,0.3-0.1c0.2,0,0.3,0,0.5,0c0.2,0,0.5-0.1,0.8-0.1H31l0.3,0.5c0.2,0.3,0.5,0.6,0.7,0.8
    c0.3,0.3,0.6,0.5,1,0.6l0.7,0.1v1.3c0.1,1.5-0.4,2.7-1.4,3.7C31.3,43.9,30,44.4,28.6,44.4z M25.1,39.7c0.4,1.8,1.3,2.3,1.9,2.7
    l0.1,0.1c0.4,0.2,1,0.3,1.6,0.3c1.1,0,1.8-0.3,2.5-0.9s0.9-1.4,0.9-2.5v0c-0.4-0.1-0.8-0.3-1-0.6c-0.4-0.3-0.7-0.6-0.9-0.9
    c-0.2,0.1-0.4,0.1-0.6,0.1c-0.2,0-0.3,0.1-0.6,0.1L28.9,38l-0.6,0.3c0,0.1-0.1,0.1-0.2,0.2c-0.1,0.1-0.2,0.2-0.3,0.3
    c-0.5,0.6-1.2,1-1.9,1C25.6,39.8,25.3,39.8,25.1,39.7z"/>
  </svg>
)

export const ImportIcon = (props) => (
  <svg x="0px" y="0px" viewBox="0 0 64 64" style={{enableBackground:'new 0 0 64 64'}} {...props}>
    <g>
      <g>
        <path  d="M32,11.1c0.8,0,1.5,0.7,1.5,1.5v28.5h-3V12.7C30.5,11.8,31.2,11.1,32,11.1L32,11.1z"/>
      </g>
    </g>
    <g>
      <g>
        <path  d="M17.7,48.1h28.3c0.9,0,1.7-0.8,1.7-1.7V20.8c0-0.9-0.8-1.7-1.7-1.7h-6.9
          c-0.8-0.3-1.1-0.8-1.2-1.5c0-0.7,0.4-1.2,1.2-1.5h9.5c1.1,0,2,0.9,2,2v30.9c0,1.1-0.9,2-2,2H15.2c-1.1,0-2-0.9-2-2V18.2
          c0-1.1,0.9-2,2-2h9.7c0.7,0.2,1.1,0.7,1.1,1.4c0,0.7-0.4,1.2-1.1,1.5h-7.1c-0.9,0-1.7,0.8-1.7,1.7v25.6
          C16,47.3,16.8,48.1,17.7,48.1z"/>
        </g>
    </g>
    <path  d="M24,34.5c0.5-0.5,1.3-0.5,1.8,0l6.1,5.7l6.1-5.7c0.5-0.5,1.3-0.5,1.8,0c0.2,0.2,0.4,0.5,0.4,0.9
    S40,36,39.8,36.2l-7.8,7.4l-7.8-7.4c-0.2-0.2-0.4-0.5-0.4-0.9C23.7,35.1,23.8,34.7,24,34.5L24,34.5z"/>
  </svg>
)

export const LockedIcon = (props) => (
  <svg x="0px" y="0px" viewBox="0 0 64 64" style={{enableBackground:'new 0 0 64 64'}} {...props}>
    <path  d="M46.3,26.1c1.6,0,2.9,1.3,2.9,2.9v19.7c0,1.6-1.3,2.9-2.9,2.9H16.8c-1.6,0-2.9-1.3-2.9-2.9V29.1
      c0-1.6,1.3-2.9,2.9-2.9H46.3z M32.1,32.2c-1.9,0-3.4,1.5-3.4,3.4c0,1.4,0.8,2.5,2,3.1v3.8c0,0.8,0.7,1.5,1.5,1.5
      c0.8,0,1.5-0.7,1.5-1.5v-3.8c1.2-0.6,2-1.7,2-3.1C35.5,33.8,34,32.2,32.1,32.2z M18.6,24c0.8,0.1,1.6-0.4,1.7-1.2
      c2.1-5.4,7.7-8.8,13.7-8c4.7,0.7,8.4,3.8,10,7.9c0.2,0.5,0.6,1,1.2,1.1c0.8,0.1,1.6-0.5,1.7-1.3c0-0.3,0-0.6-0.2-0.9
      c-2-5-6.6-8.9-12.4-9.7c-7.5-1.1-14.5,3.2-17,10.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1C17.2,23.1,17.8,23.9,18.6,24z"
    />
  </svg>
)

export const PenIcon = (props) => (
  <svg x="0px" y="0px" viewBox="0 0 64 64" style={{enableBackground:'new 0 0 64 64'}} {...props}>
    <g>
      <g transform="translate(-3693.000000, -787.000000)">
        <path  d="M3711.2,839.4c-0.3,0-0.6-0.1-0.8-0.3l-4.7-4.7c-0.4-0.4-0.4-1-0.1-1.5c11.8-13.9,10.7-24.2,10.7-24.3
          c-0.1-0.5,0.2-1,0.7-1.1l15.6-6.1c0.1,0,0.3-0.1,0.4-0.1c0.3,0,0.6,0.1,0.8,0.3l9.4,9.4c0.3,0.3,0.4,0.8,0.2,1.2l-6.1,15.6
          c-0.2,0.4-0.6,0.7-1,0.7l-0.1,0c-0.1,0-0.3,0-0.8,0c-2.5,0-11.8,0.8-23.5,10.8C3711.7,839.3,3711.5,839.4,3711.2,839.4z
           M3711.3,836.9c11.6-9.6,20.6-10.6,24-10.6c0.1,0,0.3,0,0.4,0l5.5-14.2l-8.4-8.4l-14.2,5.5c0.1,3-0.6,12.3-10.6,24.4L3711.3,836.9
          z"/>
        <path  d="M3708.9,837.1c-0.3,0-0.6-0.1-0.8-0.3c-0.4-0.4-0.4-1.1,0-1.5l17.5-17.5c0.2-0.2,0.5-0.3,0.8-0.3
          c0.3,0,0.6,0.1,0.8,0.3c0.2,0.2,0.3,0.5,0.3,0.8c0,0.3-0.1,0.6-0.3,0.8l-17.5,17.5C3709.5,836.9,3709.2,837.1,3708.9,837.1z"/>
        <path  d="M3727.8,820.1c-0.8,0-1.6-0.3-2.2-0.9c-1.2-1.2-1.2-3.2,0-4.4c0.6-0.6,1.4-0.9,2.2-0.9c0.8,0,1.6,0.3,2.2,0.9
          c0.6,0.6,0.9,1.4,0.9,2.2s-0.3,1.6-0.9,2.2C3729.4,819.8,3728.7,820.1,3727.8,820.1z M3727.8,816.1c-0.3,0-0.5,0.1-0.7,0.3
          c-0.4,0.4-0.4,1,0,1.3c0.2,0.2,0.4,0.3,0.7,0.3l0,0c0.3,0,0.5-0.1,0.7-0.3c0.4-0.4,0.4-1,0-1.3
          C3728.3,816.2,3728.1,816.1,3727.8,816.1L3727.8,816.1z M3745.1,810.7c-0.3,0-0.6-0.1-0.8-0.3l-9.9-9.9c-0.4-0.4-0.4-1.1,0-1.5
          c0.2-0.2,0.5-0.3,0.8-0.3c0.3,0,0.6,0.1,0.8,0.3l9.9,9.9c0.2,0.2,0.3,0.5,0.3,0.8c0,0.3-0.1,0.6-0.3,0.8
          C3745.7,810.6,3745.4,810.7,3745.1,810.7"/>
      </g>
    </g>
  </svg>
)
export const RedoIcon = (props) => (
  <svg x="0px" y="0px" viewBox="0 0 64 64" style={{enableBackground:'new 0 0 64 64'}} {...props}>
    <path  d="M28.3,18.4h19.5l-5.9-5.9c-0.7-0.7-0.7-1.8,0-2.5c0.7-0.7,1.8-0.7,2.5,0l7.8,7.8
    c0.7,0.7,1,1.6,1,2.5c0,1-0.4,1.9-1,2.6l-7.8,7.8c-0.3,0.3-0.8,0.5-1.3,0.5c-0.5,0-0.9-0.2-1.3-0.5c-0.7-0.7-0.7-1.8,0-2.5L48,22
    H28.3c-6.6,0-12,5.4-12,12s5.4,12,12,12h12.8c1,0,1.8,0.8,1.8,1.8c0,1-0.8,1.8-1.8,1.8H28.3c-8.6,0-15.5-7-15.5-15.5
    S19.7,18.4,28.3,18.4z"/>
  </svg>
)
export const RubberIcon = (props) => (
  <svg x="0px" y="0px" viewBox="0 0 64 64" style={{enableBackground:'new 0 0 64 64'}} {...props}>
    <path  d="M29.4,51.8c-2.8,0-5.2-1-7.1-2.9l-9.2-9.2c-0.9-0.9-1.4-2.1-1.4-3.3s0.5-2.4,1.4-3.3l20.6-20.7
      c0.9-0.9,2.1-1.4,3.3-1.4c1.2,0,2.4,0.5,3.3,1.4l13,13c0.9,1,1.4,2.3,1.4,3.4c0,1.2-0.5,2.4-1.4,3.3L36.5,48.9
      C34.5,50.8,32,51.8,29.4,51.8z M14.9,34.8c-0.3,0.3-0.5,0.8-0.5,1.3c0,0.5,0.2,0.9,0.5,1.3l4.2,4.2l5.9,5.8c1,1,2.5,1.6,4.2,1.6
      c1.9,0,3.9-0.7,5.1-1.8L18.5,31.3L14.9,34.8z M36.4,45.1l3.4-3.4l11.5-11.6c0.2-0.2,0.4-0.9,0.4-1.4c0-0.5-0.2-0.9-0.5-1.3L38,14.3
      c-0.3-0.3-0.8-0.5-1.3-0.5s-0.9,0.2-1.3,0.5l-14.9,15L36.4,45.1z"/>
  </svg>
)
export const SaveIcon = (props) => (
  <svg x="0px" y="0px" viewBox="0 0 64 64" style={{enableBackground:'new 0 0 64 64'}} {...props}>
  <path  d="M45.5,50.2h-27c-3.7,0-6.7-3-6.7-6.7V16.9c0-3.7,3-6.7,6.7-6.7h27c3.7,0,6.7,3,6.7,6.7v26.6
    C52.2,47.2,49.2,50.2,45.5,50.2z M18.5,12.8c-2.3,0-4.1,1.8-4.1,4.1v26.6c0,2.3,1.8,4.1,4.1,4.1h27c2.3,0,4.1-1.8,4.1-4.1V16.9
    c0-2.3-1.8-4.1-4.1-4.1H18.5z M45,30.2H19.1V10.2H45L45,30.2z M21.7,27.6h20.7V12.8H21.7V27.6z M35.9,16.6h2.6v8.7h-2.6V16.6z"/>
  </svg>
)

export const SelectIcon = (props) => (
  <svg x="0px" y="0px" viewBox="0 0 64 64" style={{enableBackground:'new 0 0 64 64'}} {...props}>
  <path  d="M17.1,5.8l0.6,6.3l2,29.3l0.3,5.1l4.1-2.7c0,0,2.6-1.5,5.5-3.6l7.3,12.9l1.5,2.7l2.6-1.5l4.9-3
  	l2.6-1.5L47,47.1l-7.3-12.9c1.9-1.1,3.9-2.2,5.8-3.3l4.1-2.4l-3.8-2.7L22,9.3L17.1,5.8z M20.6,11.7l23.5,16.5c0,0-3.8,2.1-8.4,4.8
  	l8.7,15.3l-4.9,3l-8.7-15.6c-4.4,2.4-8.1,5.1-8.1,5.1L20.6,11.7L20.6,11.7z"/>
  </svg>
)

export const ShapeIcon = (props) => (
  <svg x="0px" y="0px" viewBox="0 0 64 64" style={{enableBackground:'new 0 0 64 64'}} {...props}>
  <path id="path-1" d="M51.6,14.6c-2.3-2.3-5.4-3.6-8.7-3.6s-6.4,1.3-8.7,3.6c-2.3,2.3-3.6,5.4-3.6,8.7c0,0.6,0,1.2,0.1,1.8H20.7
  	c-3.2,0-5.9,2.6-5.9,5.9v14.2c0,3.2,2.6,5.9,5.9,5.9h14.2c3.2,0,5.9-2.6,5.9-5.9v-9.8c0.7,0.1,1.4,0.2,2.2,0.2
  	c3.3,0,6.4-1.3,8.7-3.6c2.3-2.3,3.6-5.4,3.6-8.7S53.9,16.9,51.6,14.6L51.6,14.6z M38.8,45.1c0,2.2-1.8,3.9-3.9,3.9H20.7
  	c-2.2,0-3.9-1.8-3.9-3.9V30.9c0-2.2,1.8-3.9,3.9-3.9h10.5c0.6,1.8,1.6,3.5,3,4.9c1.3,1.3,2.9,2.3,4.6,2.9L38.8,45.1L38.8,45.1
  	L38.8,45.1z M38.8,32.8c-2.5-1.1-4.5-3.2-5.5-5.7h1.6c2.2,0,3.9,1.8,3.9,3.9L38.8,32.8z M42.9,33.6c-0.7,0-1.5-0.1-2.2-0.2v-2.5
  	c0-3.2-2.6-5.9-5.9-5.9h-2.2c-0.1-0.6-0.2-1.2-0.2-1.8c0-5.7,4.6-10.3,10.3-10.3c5.7,0,10.3,4.6,10.3,10.3
  	C53.2,29,48.6,33.6,42.9,33.6L42.9,33.6z"/>
  </svg>
)


export const StrokeIcon = (props) => (
  <svg x="0px" y="0px" viewBox="0 0 64 64" style={{enableBackground:'new 0 0 64 64'}} {...props}>
  <path  d="M15.1,39.5L39.6,15c1.4-1.4,3.3-2.2,5.3-2.2c4.1,0,7.4,3.2,7.5,7.3c0,2-0.8,4-2.2,5.3L25.8,50.2L12,53.3
  	L15.1,39.5z M18,47.3v2.1l5.9-1.4l-0.1-6.6h-6.5l-1.4,5.9H18z M24.8,39.1c0.7,0,1.1,0.4,1.1,1.1v6.4l19.2-19.3L37.6,20l-19,19.1
  	H24.8z M46.7,25.7l2-1.9c0.9-0.9,1.5-2.2,1.6-3.9c0-2.8-2.3-5.2-5.2-5.2c-2.3,0-3.7,1.6-3.8,1.6l-2,2L46.7,25.7z"/>
  </svg>
)


export const TextIcon = (props) => (
  <svg x="0px" y="0px" viewBox="0 0 64 64" style={{enableBackground:'new 0 0 64 64'}} {...props}>
  <polygon  points="51,14.3 13.5,14.3 13.5,17.3 30.7,17.3 30.7,53.3 33.7,53.3 33.7,17.3 51,17.3 "/>
  </svg>
)


export const UndoIcon = (props) => (
  <svg x="0px" y="0px" viewBox="0 0 64 64" style={{enableBackground:'new 0 0 64 64'}} {...props}>
  <path  d="M35.8,20.9H16.3l5.9-5.9c0.7-0.7,0.7-1.8,0-2.5c-0.7-0.7-1.8-0.7-2.5,0l-7.8,7.8
	c-0.7,0.7-1,1.6-1,2.5c0,1,0.4,1.9,1,2.6l7.8,7.8c0.3,0.3,0.8,0.5,1.3,0.5c0.5,0,0.9-0.2,1.3-0.5c0.7-0.7,0.7-1.8,0-2.5L16,24.5
	h19.8c6.6,0,12,5.4,12,12s-5.4,12-12,12H23c-1,0-1.8,0.8-1.8,1.8c0,1,0.8,1.8,1.8,1.8h12.8c8.6,0,15.5-7,15.5-15.5
	S44.4,20.9,35.8,20.9z"/>
  </svg>
)

export const UnlockIcon = (props) => (
  <svg x="0px" y="0px" viewBox="0 0 64 64" style={{enableBackground:'new 0 0 64 64'}} {...props}>
  <path  d="M32.8,29.1c-1.8,0-3.3,1.5-3.3,3.3c0,1.3,0.8,2.5,1.9,3v3.7c0,0.8,0.6,1.4,1.4,1.4
  	c0.8,0,1.4-0.6,1.4-1.4v-3.7c1.1-0.5,1.9-1.7,1.9-3C36.2,30.6,34.7,29.1,32.8,29.1z M46.7,22.4H18.1c-1.6,0-2.9,1.3-2.9,2.9v19
  	c0,1.6,1.3,2.9,2.9,2.9h28.6c1.6,0,2.9-1.3,2.9-2.9v-19C49.5,23.7,48.2,22.4,46.7,22.4z M46.7,42.4c0,1.6-0.3,1.9-1.9,1.9H20
  	c-1.6,0-1.9-0.3-1.9-1.9V27.2c0-1.6,0.3-1.9,1.9-1.9h24.8c1.6,0,1.9,0.3,1.9,1.9V42.4z M18.9,21c0.8,0,1.4-0.6,1.5-1.4l0,0
  	c1.3-5.5,6.2-9.5,12-9.5c4.6,0,8.6,2.5,10.7,6.2c0.2,0.5,0.7,0.9,1.3,0.9c0.8,0,1.4-0.6,1.4-1.4c0-0.3-0.1-0.6-0.3-0.8
  	c-2.6-4.6-7.5-7.6-13.2-7.6c-7.4,0-13.5,5.1-14.9,12c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1l0,0c0,0,0,0.1,0,0.1
  	C17.4,20.3,18.1,21,18.9,21z"/>
  </svg>
)

export const SlideIcon = (props) => (
  <svg x="0px" y="0px" viewBox="0 0 64 64" style={{enableBackground:'new 0 0 64 64'}} {...props}>
    <path d="M42 10c-2.21-.7-4.66-1-7-1-3.9 0-8.1.8-11 3-2.9-2.2-7.1-3-11-3s-8.1.8-11 3v29.3c0 .5.5 1 1 1 .2 0 .3-.1.5-.1C6.2 40.9 10.1 40 13 40c3.9 0 8.1.8 11 3 2.7-1.7 7.6-3 11-3 3.3 0 6.7.6 9.5 2.1.2.1.3.1.5.1.5 0 1-.5 1-1V12c-1.2-.9-2.5-1.5-4-2zm0 27c-2.2-.7-4.6-1-7-1-3.4 0-8.3 1.3-11 3V16c2.7-1.7 7.6-3 11-3 2.4 0 4.8.3 7 1v23z"/>
  </svg>
)

export const ArrowIcon = (props) => (
  <svg x="0px" y="0px" viewBox="0 0 64 64" style={{enableBackground:'new 0 0 64 64'}} {...props}>
    <path d="M44 24l-8-8v6H6v4h30v6z"/>
  </svg>
)

export const TriangleIcon = (props) => (
  <svg x="0px" y="0px" viewBox="0 0 64 64" style={{enableBackground:'new 0 0 64 64'}} {...props}>
    <path fill="none" d="M-838-2232H562v3600H-838v-3600z"/>
    <path d="M24 15.55L36.78 36H11.22L24 15.55M24 8L4 40h40L24 8z"/>
    <path fill="none" d="M0 0h48v48H0V0z"/>
  </svg>
)

export const ImageIcon = (props) => (
  <svg x="0px" y="0px" viewBox="0 0 64 64" style={{enableBackground:'new 0 0 64 64'}} {...props}>
    <path d="M4 12H0v10h.02L0 40c0 2.21 1.79 4 4 4h36v-4H4V12zm40-4H28l-4-4H12C9.79 4 8.02 5.79 8.02 8L8 32c0 2.21 1.79 4 4 4h32c2.21 0 4-1.79 4-4V12c0-2.21-1.79-4-4-4zM14 30l9-12 7 9.01L35 21l7 9H14z"/>
  </svg>
)

export const LineIcon = (props) => (
  <svg x="0px" y="0px" viewBox="0 0 64 64" style={{enableBackground:'new 0 0 64 64'}} {...props}>
    <path d="M38 26H10v-4h28v4z"/>
  </svg>
)

export const CurveIcon = (props) => (
  <svg x="0px" y="0px" viewBox="0 0 64 64" style={{enableBackground:'new 0 0 64 64'}} {...props}>
    <path d="M7 36.99l12-12.03 8 8 17-19.12-2.82-2.83L27 26.96l-8-8L4 33.99z"/>
  </svg>
)

export const DelIcon = (props) => (
  <svg x="0px" y="0px" viewBox="0 0 64 64" style={{enableBackground:'new 0 0 64 64'}} {...props}>
    <path d="M29.17 16L24 21.17 18.83 16 16 18.83 21.17 24 16 29.17 18.83 32 24 26.83 29.17 32 32 29.17 26.83 24 32 18.83 29.17 16zM24 4C12.95 4 4 12.95 4 24s8.95 20 20 20 20-8.95 20-20S35.05 4 24 4zm0 36c-8.82 0-16-7.18-16-16S15.18 8 24 8s16 7.18 16 16-7.18 16-16 16z"/>
  </svg>
)

export const CopyIcon = (props) => (
  <svg x="0px" y="0px" viewBox="0 0 64 64" style={{enableBackground:'new 0 0 64 64'}} {...props}>
    <path d="M28 4H12C9.79 4 8.02 5.79 8.02 8L8 40c0 2.21 1.77 4 3.98 4H36c2.21 0 4-1.79 4-4V16L28 4zm4 28h-6v6h-4v-6h-6v-4h6v-6h4v6h6v4zm-6-14V7l11 11H26z"/>
  </svg>
)

export const BoldIcon = (props) => (
  <svg x="0px" y="0px" viewBox="0 0 64 64" style={{enableBackground:'new 0 0 64 64'}} {...props}>
    <path d="M31.2 21.58c1.93-1.35 3.3-3.53 3.3-5.58 0-4.51-3.49-8-8-8H14v28h14.08c4.19 0 7.42-3.4 7.42-7.58 0-3.04-1.73-5.63-4.3-6.84zM20 13h6c1.66 0 3 1.34 3 3s-1.34 3-3 3h-6v-6zm7 18h-7v-6h7c1.66 0 3 1.34 3 3s-1.34 3-3 3z"/>
  </svg>
)

export const EditIcon = (props) => (
  <svg x="0px" y="0px" viewBox="0 0 64 64" style={{enableBackground:'new 0 0 64 64'}} {...props}>
    <path d="M35.5 14L28 6.5l-20 20V34h7.5l20-20zm5.91-5.91c.78-.78.78-2.05 0-2.83L36.74.59c-.78-.78-2.05-.78-2.83 0L30 4.5l7.5 7.5 3.91-3.91z"/><path fillOpacity=".36" d="M0 40h48v8H0z"/>
  </svg>
)




// export const EllipseIcon = (props) => (
//   <svg x="0px" y="0px" viewBox="0 0 64 64" style={{enableBackground:'new 0 0 64 64'}} {...props}>
//     <ellipse cx="64" cy="32" rx="60" ry="30" style="fill:None;stroke:black;stroke-width:2"/>
//     {/* <path d="M7 36.99l12-12.03 8 8 17-19.12-2.82-2.83L27 26.96l-8-8L4 33.99z"/> */}
//   </svg>
// )








