import React from 'react';
import ReactDOM from 'react-dom';

import {BrowserRouter, Route} from 'react-router-dom';
import {Switch} from 'react-router';
import {Provider} from 'react-redux';
import './css/hy.css';
// import App from './App';
import * as serviceWorker from './serviceWorker';

import {configStore, loadStoreState} from './comm/store';
import AppRoute from './comm/route';


const initState = loadStoreState();
const store = configStore(initState);

const routes = (
    <Provider store={store}>
        <BrowserRouter>
            <Switch>
                <Route path='/confBoard' component={AppRoute}/>
            </Switch>
        </BrowserRouter>
    </Provider>
);


ReactDOM.render(routes, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
