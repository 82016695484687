import {fabric} from "fabric"


/**
 * Determine the mouse position
 *
 * @param event the canvas event
 * @returns *[] tuple of position x,y
 * @private
 */
export const pointerPosition = (event) => {
    event = event || window.event;
    var target = event.target || event.srcElement,
      style = target.currentStyle || window.getComputedStyle(target, null),
      borderLeftWidth = parseInt(style['borderLeftWidth'], 10),
      borderTopWidth = parseInt(style['borderTopWidth'], 10),
      rect = target.getBoundingClientRect(),
      _x = event.clientX - borderLeftWidth - rect.left,
      _y = event.clientY - borderTopWidth - rect.top,
      _touchX = event.changedTouches ? event.changedTouches[0].clientX - borderLeftWidth - rect.left : null,
      _touchY = event.changedTouches ? event.changedTouches[0].clientY - borderTopWidth - rect.top : null;
    return [(_x || _touchX), (_y || _touchY)];
  };
  
  /**
   * Calculate the distance of two x,y points
   *
   * @param point1 an object with x,y attributes representing the start point
   * @param point2 an object with x,y attributes representing the end point
   *
   * @returns {number}
   */
  export const linearDistance = (point1, point2) => {
    let xs = point2.x - point1.x;
    let ys = point2.y - point1.y;
    return Math.sqrt(xs * xs + ys * ys);
  };
  
  /**
   * Return a random uuid of the form xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx
   * @returns {string}
   */
  export const uuid4 = () => {
    let uuid = '', ii;
    for (ii = 0; ii < 32; ii += 1) {
      switch (ii) {
        case 8:
        case 20:
          uuid += '-';
          uuid += (Math.random() * 16 | 0).toString(16);
          break;
        case 12:
          uuid += '-';
          uuid += '4';
          break;
        case 16:
          uuid += '-';
          uuid += (Math.random() * 4 | 8).toString(16);
          break;
        default:
          uuid += (Math.random() * 16 | 0).toString(16);
      }
    }
    return uuid;
  };


// 抽象类，供其他类来继承
export class FabricCanvasTool {
    constructor(canvas, parent=undefined) {
      this._canvas = canvas;
      this._parent = parent;
    }
  
    configureCanvas(props) {
  
    }
  
    doMouseUp(event) {
  
    }
  
    doMouseDown(event) {
      // console.log('@@@ props = ', this.props)
    }
  
    doMouseMove(event) {
  
    }
  
    doMouseOut(event) {
  
    }
  }


export class DefaultTool extends FabricCanvasTool {
    configureCanvas(props) {
      let canvas = this._canvas;
      canvas.isDrawingMode = false;
      // canvas.isDrawingMode = canvas.selection = false;
      canvas.forEachObject((o) => o.selectable = o.evented = false);
      canvas.discardActiveObject();
      canvas.defaultCursor = 'default';
      canvas.hoverCursor = 'default'
      canvas.selection = false
      // canvas.renderAll();


    }

    doMouseDown(o) {
      let canvas = this._canvas;
      canvas.forEachObject((o) => o.selectable = o.evented = false);
      // // o.selectable = false
      // canvas.discardActiveObject();
      // console.log("@@@@_1")
    }

    doMouseUp(o) {
      let canvas = this._canvas;
      canvas.forEachObject((o) => o.selectable = o.evented = false);
      // o.selectable = false
      // canvas.discardActiveObject();
      // console.log("@@@@_2")
    }

    doMouseMove(o) {
      let canvas = this._canvas;
      canvas.forEachObject((o) => o.selectable = o.evented = false);
      // o.selectable = false
      // canvas.discardActiveObject();
      // console.log("@@@@_3")
    }
  
  }


export class Arrow extends FabricCanvasTool {
    configureCanvas(props) {
      let canvas = this._canvas;
      canvas.isDrawingMode = canvas.selection = false;
      canvas.forEachObject((o) => o.selectable = o.evented = false);
      this._width = props.lineWidth;
      this._color = props.lineColor;
    }
  
    doMouseDown(o) {
      this.isDown = true;
      let canvas = this._canvas;
      var pointer = canvas.getPointer(o.e);
      var points = [pointer.x, pointer.y, pointer.x, pointer.y];
      this.line = new fabric.Line(points, {
        strokeWidth: this._width,
        fill: this._color,
        stroke: this._color,
        originX: 'center',
        originY: 'center',
        selectable: false,
        evented: false
      });
  
      this.head = new fabric.Triangle({
        fill: this._color,
        left: pointer.x,
        top: pointer.y,
        originX: 'center',
        originY: 'center',
        height: 3 * this._width,
        width: 3 * this._width,
        selectable: false,
        evented: false,
        angle: 90
      });
      
      this._parent._isDrawShape = true
      canvas.add(this.line);
      canvas.add(this.head);
    }
  
    doMouseMove(o) {
      if (!this.isDown) return;
      let canvas = this._canvas;
      var pointer = canvas.getPointer(o.e);
      this.line.set({ x2: pointer.x, y2: pointer.y });
      this.line.setCoords();
  
      let x_delta = pointer.x - this.line.x1;
      let y_delta = pointer.y - this.line.y1;
  
      this.head.set({ 
        left: pointer.x, 
        top: pointer.y, 
        angle: 90 + Math.atan2(y_delta, x_delta) * 180/Math.PI 
      });
  
      canvas.renderAll();
    }
  
    doMouseUp(o) {
      this.isDown = false;
      this._parent._isDrawShape = false
      
      let canvas = this._canvas;
  
      canvas.remove(this.line);
      canvas.remove(this.head);
      let arrow = new fabric.Group([this.line, this.head]);
      canvas.add(arrow);
    }
  
    doMouseOut(o) {
      this.isDown = false;
      this._parent._isDrawShape = false
      this._parent._publishChange()
    }
  }

export class Circle extends FabricCanvasTool {

    configureCanvas(props) {
      let canvas = this._canvas;
      canvas.isDrawingMode = canvas.selection = false;
      canvas.forEachObject((o) => o.selectable = o.evented = false);
      this._width = props.lineWidth;
      this._color = props.lineColor;
      this._fill = props.fillColor;
    }
  
    doMouseDown(o) {
      let canvas = this._canvas;
      this.isDown = true;
      let pointer = canvas.getPointer(o.e);
      [this.startX, this.startY] = [pointer.x, pointer.y];
      this.circle = new fabric.Circle({
        left: this.startX, top: this.startY,
        originX: 'left', originY: 'center',
        strokeWidth: this._width,
        stroke: this._color,
        fill: this._fill,
        selectable: false,
        evented: false,
        radius: 1
      });
      this._parent._isDrawShape = true
      canvas.add(this.circle);
    }
  
    doMouseMove(o) {
      if (!this.isDown) return;
      let canvas = this._canvas;
      let pointer = canvas.getPointer(o.e);
      this.circle.set({
        radius: linearDistance({ x: this.startX, y: this.startY }, { x: pointer.x, y: pointer.y }) / 2,
        angle: Math.atan2(pointer.y - this.startY, pointer.x - this.startX) * 180 / Math.PI
      });
      this.circle.setCoords();
      canvas.renderAll();
    }
  
    doMouseUp(o) {
      this.isDown = false;
      this._parent._isDrawShape = false
      this._parent._publishChange()
      
    }
  }

export class Ellipse extends FabricCanvasTool {

    configureCanvas(props) {
      let canvas = this._canvas;
      canvas.isDrawingMode = canvas.selection = false;
      canvas.forEachObject((o) => o.selectable = o.evented = false);
      this._width = props.lineWidth;
      this._color = props.lineColor;
      this._fill = props.fillColor;
    }
  
    doMouseDown(o) {
      let canvas = this._canvas;
      this.isDown = true;
      let pointer = canvas.getPointer(o.e);
      [this.startX, this.startY] = [pointer.x, pointer.y];
      this.ellipse = new fabric.Ellipse({
        left: this.startX, top: this.startY,
        originX: 'left', originY: 'center',
        strokeWidth: this._width,
        stroke: this._color,
        fill: this._fill,
        selectable: false,
        evented: false,
        rx: 1, 
        ry: 1
      });
      this._parent._isDrawShape = true
      canvas.add(this.ellipse);
    }
  
    doMouseMove(o) {
      if (!this.isDown) return;
      let canvas = this._canvas;
      let pointer = canvas.getPointer(o.e);
      let origin_x = 'left'
      if (pointer.x < this.startX) {
        origin_x = 'right'
      }
      this.ellipse.set({
        rx:linearDistance({ x: this.startX, y: 0 }, { x: pointer.x, y: 0 }),
        ry:linearDistance({ x: 0, y: this.startY }, { x: 0, y: pointer.y }),
        originX: origin_x, 
        // radius: linearDistance({ x: this.startX, y: this.startY }, { x: pointer.x, y: pointer.y }) / 2,
        // angle: Math.atan2(pointer.y - this.startY, pointer.x - this.startX) * 180 / Math.PI
      });
      this.ellipse.setCoords();
      canvas.renderAll();
    }
  
    doMouseUp(o) {
      this.isDown = false;
      this._parent._isDrawShape = false
      this._parent._publishChange()
    }
  }

export class History {
    constructor(undoLimit = 10, debug = false) {
      this.undoLimit = undoLimit;
      this.undoList = [];
      this.redoList = [];
      this.current = null;
      this.debug = debug;
    }
  
    /**
     * Get the limit of undo/redo actions
     *
     * @returns {number|*} the undo limit, as it is configured when constructing the history instance
     */
    getUndoLimit() {
      return this.undoLimit;
    }
  
    /**
     * Get Current state
     *
     * @returns {null|*}
     */
    getCurrent() {
      return this.current;
    }
  
    /**
     * Keep an object to history
     *
     * This method will set the object as current value and will push the previous "current" object to the undo history
     *
     * @param obj
     */
    keep(obj) {
      try {
        this.redoList = [];
        if (this.current) {
          this.undoList.push(this.current);
        }
        if (this.undoList.length > this.undoLimit) {
          this.undoList.shift();
        }
        this.current = obj;
      } finally {
        this.print();
      }
    }
  
    /**
     * Undo the last object, this operation will set the current object to one step back in time
     *
     * @returns the new current value after the undo operation, else null if no undo operation was possible
     */
    undo() {
      try {
        if (this.current) {
          this.redoList.push(this.current);
          if (this.redoList.length > this.undoLimit) {
            this.redoList.shift();
          }
          if (this.undoList.length === 0) this.current = null;
        }
        if (this.undoList.length > 0) {
          this.current = this.undoList.pop();
          return this.current;
        }
        return null;
      } finally {
        this.print();
      }
    }
  
    /**
     * Redo the last object, redo happens only if no keep operations have been performed
     *
     * @returns the new current value after the redo operation, or null if no redo operation was possible
     */
    redo() {
      try {
        if (this.redoList.length > 0) {
          if (this.current) this.undoList.push(this.current);
          this.current = this.redoList.pop();
          return this.current;
        }
        return null;
      } finally {
        this.print();
      }
    }
  
    /**
     * Checks whether we can perform a redo operation
     *
     * @returns {boolean}
     */
    canRedo() {
      return this.redoList.length > 0;
    }
  
    /**
     * Checks whether we can perform an undo operation
     *
     * @returns {boolean}
     */
    canUndo() {
      return this.undoList.length > 0 || this.current !== null;
    }
  
    /**
     * Clears the history maintained, can be undone
     */
    clear() {
      this.undoList = [];
      this.redoList = [];
      this.current = null;
      this.print();
    }
  
    print() {
      if (this.debug) {
        /* eslint-disable no-console */
        console.log(
          this.undoList,
          ' -> ' + this.current + ' <- ',
          this.redoList.slice(0).reverse(),
        );
      }
    }
  }

export class Line extends FabricCanvasTool {

    configureCanvas(props) {
      let canvas = this._canvas;
      canvas.isDrawingMode = canvas.selection = false;
      canvas.forEachObject((o) => o.selectable = o.evented = false);
      this._width = props.lineWidth;
      this._color = props.lineColor;
    }
  
    doMouseDown(o) {
      this.isDown = true;
      let canvas = this._canvas;
      var pointer = canvas.getPointer(o.e);
      var points = [pointer.x, pointer.y, pointer.x, pointer.y];
      this.line = new fabric.Line(points, {
        strokeWidth: this._width,
        fill: this._color,
        stroke: this._color,
        originX: 'center',
        originY: 'center',
        selectable: false,
        evented: false
      });
      this._parent._isDrawShape = true
      canvas.add(this.line);
    }
  
    doMouseMove(o) {
      if (!this.isDown) return;
      let canvas = this._canvas;
      var pointer = canvas.getPointer(o.e);
      this.line.set({ x2: pointer.x, y2: pointer.y });
      this.line.setCoords();
      canvas.renderAll();
    }
  
    doMouseUp(o) {
      this.isDown = false;
      this._parent._isDrawShape = false
      this._parent._publishChange()
    }
  
    doMouseOut(o) {
      this.isDown = false;
      this._parent._isDrawShape = false
      this._parent._publishChange()
    }
  }

export class Pan extends FabricCanvasTool {

    configureCanvas(props) {
      let canvas = this._canvas;
      canvas.isDrawingMode = canvas.selection = false;
      canvas.forEachObject((o) => o.selectable = o.evented = false);
      //Change the cursor to the move grabber
      canvas.defaultCursor = 'move';
    }
  
    doMouseDown(o) {
      let canvas = this._canvas;
      this.isDown = true;
      let pointer = canvas.getPointer(o.e);
      this.startX = pointer.x;
      this.startY = pointer.y;
    }
  
    doMouseMove(o) {
      if (!this.isDown) return;
      let canvas = this._canvas;
      let pointer = canvas.getPointer(o.e);
  
      canvas.relativePan({
        x: pointer.x - this.startX,
        y: pointer.y - this.startY
      });
      canvas.renderAll();
    }
  
    doMouseUp(o) {
      this.isDown = false;
    }
  
  }

export class Pencil extends FabricCanvasTool {

    configureCanvas(props) {
      this._canvas.isDrawingMode = true;
      this._canvas.freeDrawingBrush.width = props.lineWidth;
      this._canvas.freeDrawingBrush.color = props.lineColor;
    }
  }

export class RectangleLabelObject {
    constructor(canvas, text, rectProps, textProps){
      this._canvas = canvas;
      this._text = text;
      this._rectObj = new fabric.Rect(rectProps);
      this._textObj = new fabric.Textbox(text, textProps);
      canvas.on({'object:scaling': this.update});
      canvas.on({'object:moving' : this.update});
    }
  
    update = (e) => {
      //e.target.set({scaleX:1, scaleY:1})
      if(!this._textObj || !this._rectObj) return;
      if(e.target === this._rectObj){
        this._textObj.set({
            'width'      : this._rectObj.getScaledWidth(),
            'scaleX'     : 1,
            'scaleY'     : 1,
            'top'        : this._rectObj.top - this._textObj.getScaledHeight(),
            'left'       : this._rectObj.left,
        })
      }
    }
  
    setText(text) {
      this._text = text;
      this._textObj.set({ text });
    }
  }

export class RectangleLabel extends FabricCanvasTool {

    configureCanvas(props) {
      let canvas = this._canvas;
      canvas.isDrawingMode = canvas.selection = false;
      canvas.forEachObject((o) => o.selectable = o.evented = false);
      this._width = props.lineWidth;
      this._color = props.lineColor;
      this._fill = props.fillColor;
      this._textString = props.text;
      this._maxFontSize = 12;
    }
  
    doMouseDown(o) {
      let canvas = this._canvas;
      this.isDown = true;
      let pointer = canvas.getPointer(o.e);
      this.startX = pointer.x;
      this.startY = pointer.y;
      this.rectangleLabel = new RectangleLabelObject(canvas, "New drawing", {
        left: this.startX,
        top: this.startY,
        originX: 'left',
        originY: 'top',
        width: pointer.x - this.startX,
        height: pointer.y - this.startY,
        stroke: this._color,
        strokeWidth: this._width,
        fill: this._fill,
        transparentCorners: false,
        selectable: false,
        evented: false,
        strokeUniform: true,
        noScaleCache : false,
        angle: 0
      },{
        left: this.startX,
        top: this.startY - 12,
        originX: 'left',
        originY: 'top',
        width: pointer.x - this.startX - this._width,
        height: canvas.height/3,
        fontSize: this._maxFontSize,
        noScaleCache: false,
        backgroundColor: this._color,
        transparentCorners: true,
        hasControls: false,
        angle: 0
      });
  
      if(this._objects && this._objects.length > 0)
        this._objects.push(this.rectangleLabel);
      else
        this._objects = [this.rectangleLabel];
  
      while (this.rectangleLabel._textObj.height >  canvas.height/3) {
        this.rectangleLabel._textObj.set({fontSize: this.rectangleLabel._textObj.fontSize-1,top: this.startY - this.rectangleLabel._textObj.fontSize - 12,});
      }
  
      canvas.add(this.rectangleLabel._rectObj);
      canvas.add(this.rectangleLabel._textObj);
      canvas.renderAll();
    }
  
    doMouseMove(o) {
      if (!this.isDown) return;
      let canvas = this._canvas;
      let pointer = canvas.getPointer(o.e);
      if (this.startX > pointer.x) {
        this.rectangleLabel._rectObj.set({ left: Math.abs(pointer.x) });
        this.rectangleLabel._textObj.set({ left: Math.abs(pointer.x) });
      }
      if (this.startY > pointer.y) {
        this.rectangleLabel._rectObj.set({ left: Math.abs(pointer.x) });
        this.rectangleLabel._textObj.set({ top: Math.abs(pointer.y) });
      }
      this.rectangleLabel._textObj.setCoords();
      this.rectangleLabel._rectObj.set({ width: Math.abs(this.startX - pointer.x) });
      this.rectangleLabel._textObj.set({ width: this.rectangleLabel._rectObj.getScaledWidth() });
      this.rectangleLabel._rectObj.set({ height: Math.abs(this.startY - pointer.y) });
      this.rectangleLabel._rectObj.setCoords();
      canvas.renderAll();
    }
  
    doMouseUp(o) {
      this.isDown = false;
      let canvas = this._canvas;
      
      // var group = new fabric.Group([this.rectangleLabel._rectObj,this.rectangleLabel._textObj]);
      // canvas.remove(this.rectangleLabel._rectObj);
      // canvas.remove(this.rectangleLabel._textObj);
      // canvas.add(group);
      canvas.renderAll();
    }
  }

export class Rectangle extends FabricCanvasTool {

    configureCanvas(props) {
      let canvas = this._canvas;
      canvas.isDrawingMode = canvas.selection = false;
      canvas.forEachObject((o) => o.selectable = o.evented = false);
      this._width = props.lineWidth;
      this._color = props.lineColor;
      this._fill = props.fillColor;
    }
  
    doMouseDown(o) {
      let canvas = this._canvas;
      this.isDown = true;
      let pointer = canvas.getPointer(o.e);
      this.startX = pointer.x;
      this.startY = pointer.y;
      this.rect = new fabric.Rect({
        left: this.startX,
        top: this.startY,
        originX: 'left',
        originY: 'top',
        width: pointer.x - this.startX,
        height: pointer.y - this.startY,
        stroke: this._color,
        strokeWidth: this._width,
        fill: this._fill,
        transparentCorners: false,
        selectable: false,
        evented: false,
        strokeUniform: true,
        noScaleCache : false,
        angle: 0
      });
      this._parent._isDrawShape = true
      canvas.add(this.rect);
    }
  
    doMouseMove(o) {
      if (!this.isDown) return;
      let canvas = this._canvas;
      let pointer = canvas.getPointer(o.e);
      if (this.startX > pointer.x) {
        this.rect.set({ left: Math.abs(pointer.x) });
      }
      if (this.startY > pointer.y) {
        this.rect.set({ top: Math.abs(pointer.y) });
      }
      this.rect.set({ width: Math.abs(this.startX - pointer.x) });
      this.rect.set({ height: Math.abs(this.startY - pointer.y) });
      this.rect.setCoords();
      canvas.renderAll();
    }
  
    doMouseUp(o) {
      this.isDown = false;
      this._parent._isDrawShape = false
      this._parent._publishChange()
    }
  }


export class Select extends FabricCanvasTool {

    configureCanvas(props) {
      let canvas = this._canvas;
      canvas.isDrawingMode = false;
      canvas.selection = true;
      canvas.forEachObject((o) => {
        o.selectable = o.evented = true;
      });
    }
  }

export class Text extends FabricCanvasTool {

    configureCanvas(props) {
      let canvas = this._canvas;
      canvas.isDrawingMode = canvas.selection = false;
      canvas.forEachObject((o) => o.selectable = o.evented = false);
      this._width = props.lineWidth;
      this._color = props.lineColor;
      this.textToolMouseDownHd = props.textToolMouseDownHd
    }
  
    doMouseDown(o) {
      this.isDown = true;
      let canvas = this._canvas;
      var pointer = canvas.getPointer(o.e);

      if (this.textToolMouseDownHd !== undefined){
        this.textToolMouseDownHd(pointer.x, pointer.y, canvas.getWidth())
      }
    }
  }

export const Tool = {
    Circle: 'circle',
    Line: 'line',
    Arrow: 'arrow',
    Pencil: 'pencil',
    Rectangle: 'rectangle',
    Ellipse: 'ellipse',
    RectangleLabel: 'rectangle-label',
    Select: 'select',
    Text: 'text', 
    Pan: 'pan',
    DefaultTool: 'default-tool', 
}

